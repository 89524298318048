import React, {FC, useEffect, useState, useTransition} from 'react';
import {Avatar, Chip, Grid, Paper, Typography} from "@mui/material";
import {OutlineBorderPaper} from "../../../../_styles/PaperStyle";
import AddTag from "../../../Tag/AddTag";
import {Delete} from "@mui/icons-material";
import tagTargetService from "../../../../services/tagTarget.service";
import TagTargetService from "../../../../services/tagTarget.service";
import {TagTarget} from "../../../../@types/tagTarget";
import {useTranslation} from "react-i18next";
import {Tutorial} from "../../../../@types/tutorial";

const TagSection: FC<{ tutorial: Tutorial }> = ({tutorial}) => {
    const {t} = useTranslation();
    const [tagTarget, setTagTarget] = useState<TagTarget>();
    const [loading, startTransition] = useTransition();

    useEffect(() => {
        startTransition(() => tagTargetService.setTagTarget(tutorial.tagTarget))
        const tagTarget = tagTargetService._tagTarget.subscribe((e: any) => setTagTarget(e));
        return () => {
            tagTarget.unsubscribe();
        };
    }, [tutorial.tagTarget]);

    return (
        <Grid item xs={12}>
            <OutlineBorderPaper>
                <Typography variant="h2" component="legend">Gestion des tags</Typography>
                {tagTarget &&
                    <Paper>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <AddTag tagTarget={tagTarget} />
                            </Grid>
                            <Grid item xs={12}>
                                {tagTarget.tags.map((tag, index) => (
                                    <Chip
                                        key={index}
                                        avatar={<Avatar alt="Natacha" src="/static/images/avatar/1.jpg"/>}
                                        label={tag.label}
                                        onDelete={() => TagTargetService.delete(index, t)}
                                        deleteIcon={<Delete/>}
                                    />
                                ))}
                            </Grid>
                        </Grid>
                    </Paper>
                }
            </OutlineBorderPaper>
        </Grid>
    );
};

export default TagSection;
