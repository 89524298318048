import React, {FC, useContext, useEffect, useState, useTransition} from 'react';
import AutoCompleteInput from "../common/formInputs/AutoCompleteInput";
import {Material} from "../../@types/material";
import materialTargetService from "../../services/materialTarget.service";
import {useTranslation} from "react-i18next";
import {PersonContext} from "../../App";
import MaterialTargetService from "../../services/materialTarget.service";
import MaterialService from "../../services/material.service";
import {Tutorial} from "../../@types/tutorial";
import {SubStep} from "../../@types/subStep";
import {Make} from "../../@types/make";
import {Person} from "../../@types/person";

const AddMaterial: FC<{ targetable:string, subStep?: SubStep }> = ({targetable, subStep}) => {
    const {t} = useTranslation();
    const [loading, startTransition] = useTransition();
    const [materials, setMaterials] = useState<any>([]);
    const [values, setValues] = React.useState<any[] | null>([]);
    const {currentPerson} = useContext(PersonContext);

    useEffect(() => {
        startTransition(() => {MaterialService.getCollection(t)})
        const materials = MaterialService._materials.subscribe((e: any) => setMaterials(e));
        return () => {
            materials.unsubscribe();
        };
    }, []);
    const addMaterialTarget = () => {
        values?.map((value, index) => {
            let data = {
                material: value['@id'],
                targetIri: (subStep) ? subStep["@id"] : "/api/people/" + currentPerson.id,
                quantity: 1
            }
            materialTargetService.post(data, targetable, (subStep) ? subStep.id : currentPerson.id, t)
        });
        setValues([])
    }
    return (
        <AutoCompleteInput collection={materials} setValues={setValues} addAction={addMaterialTarget} placeholder="Material"/>
    )
};

export default AddMaterial;
