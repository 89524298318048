import {Box, Card, CardActionArea, CardContent, CardMedia, Stack, styled, Typography} from "@mui/material";
import React, {FC, useState, useTransition} from "react";
import {useTranslation} from "react-i18next";
import ItemSkeleton from "../../../layout/skeleton/ItemSkeleton";
import Button from "@mui/material/Button";
import materialTargetService from "../../../../services/materialTarget.service";
import MaterialTargetService from "../../../../services/materialTarget.service";
import {MaterialTargetJson} from "../../../../@types/materialTarget";

const Container = styled(Box)(({theme}: any) => ({
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
    backgroundColor: theme.palette.grey[500_8],
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
}));

const MaterialTargetCard: FC<{ materialTarget: any, targetable: string, targetableId: string, }>
    = ({materialTarget, targetable, targetableId}) => {
    const {t} = useTranslation();
    const [loading, startTransition] = useTransition();
    const [hover, setHover] = useState<boolean>(false);
    const handleOpen = () => {
        setHover(true)
    }

    const handleSubmit = (quantity:number) => {
        let data: Partial<MaterialTargetJson> = {}
        data.quantity = quantity
        console.log('quantity',data)
        startTransition(() => {
            materialTargetService.put(materialTarget.id, data, targetable, targetableId, t)
        })
    }

    const deleteMaterialTarget = () =>
        MaterialTargetService.delete(materialTarget.material.name, materialTarget.id, targetable, targetableId, t)

    return <Container onClick={handleOpen}>
        {loading ? <ItemSkeleton/> :
            <Card sx={{width: '100%', height: 250}} onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)}>
                {hover ?
                    <Box height='100%'>
                        <Stack direction={'row'} height='70%'>
                            <Box width='33%' textAlign='center'
                                 bgcolor={theme => theme.palette.primary.mainTransparency['80']}>
                                <Button sx={{height: '100%', width: '100%', minWidth: 'auto'}} color="secondary" onClick={() => {
                                    handleSubmit(materialTarget.quantity - 1)
                                }}>
                                    <Typography variant={"h1"} component="p">-</Typography>
                                </Button>
                            </Box>
                            <Box width='33%' textAlign='center' alignItems='center' display='inline-grid'>
                                <Typography variant="quantity">{materialTarget.quantity}</Typography>
                            </Box>
                            <Box width='33%' textAlign='center'
                                 bgcolor={theme => theme.palette.secondary.mainTransparency['80']}>
                                <Button sx={{height: '100%', width: '100%', minWidth: 'auto'}}
                                        onClick={() => {
                                            handleSubmit(materialTarget.quantity + 1)
                                        }}>
                                    <Typography variant={"h3"} component="p">+</Typography>
                                </Button>
                            </Box>
                        </Stack>
                        <Box height='30%' textAlign='center' bgcolor={theme => theme.palette.error.main}>
                            <Button sx={{height: '100%', width: '100%', minWidth: 'auto'}} color="button"
                            onClick={deleteMaterialTarget}>
                            <Typography variant={"h1"} component="p">X</Typography>
                        </Button>
                        </Box>
                    </Box>
                    :
                    <CardActionArea>
                        <CardMedia
                            component="img"
                            height="175"
                            src={materialTarget.material?.coverPicture?.contentUrl ?? "https://cdn.discordapp.com/attachments/1077401840246870048/1198735070467469442/ninde_beren_a_baby_dragon_flying_watercolor_background_white_cu_065ce9a6-ccc5-40c0-ba30-61b0d04bf335.png?ex=65bffc08&is=65ad8708&hm=5cf1936549cc72085acfc24eda75a884652b63ba76b6cb39e0472246522ef03f&"}
                            alt={materialTarget.material.name}
                        />
                        <CardContent>
                            <Stack direction={"row"} sx={{width: '100%'}} justifyContent="space-between"
                                   alignItems="center">

                                <Typography variant="h4" component="h4">
                                    {materialTarget.material.name}
                                </Typography>
                                <Typography variant="quantity" component="p">
                                    x{materialTarget.quantity}
                                </Typography>
                            </Stack>

                        </CardContent>
                    </CardActionArea>
                }
            </Card>
        }
    </Container>;
}

export default MaterialTargetCard;

