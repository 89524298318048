import React, {FC, useTransition} from 'react';
import {Box, FormControlLabel, Radio, Stack, Typography} from "@mui/material";
import RadioGroupInput from "../../../../common/formInputs/RadioGroupInput";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import stepService from "../../../../../services/subStep.service";
import {SubStep} from "../../../../../@types/subStep";
import {directions} from "../../../../../_data/stepData";
import {CustomFormControlLabel, CustomRadio} from "../../../../../_styles/FormInputStyle";
import SubStepService from "../../../../../services/subStep.service";
import {Step} from "../../../../../@types/step";

const FormDirectionConfiguration: FC<{ subStep: SubStep, tutorialId: string }>
    = ({subStep, tutorialId}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [loading, startTransition] = useTransition();


    const handleClick = (data: any) => {
        console.log('data', data);
        startTransition(() => {
            SubStepService.put(subStep.id, {direction: data}, tutorialId, t)
        })
    };

    return (
        <Stack spacing={2} mt={5}>
            <Typography id="modal-modal-title" variant="h4" component="h4" style={{textAlign: "center"}}>
                {t("subStep.direction_configuration", {ns: "tutorial/tutorial"})}
            </Typography>
                <RadioGroupInput ariaLabelledBy="radio-button" name="config" helperText="" defaultValue={subStep.direction}
                                 error={false}>
                    {directions.map((direction, index) => (
                        <CustomFormControlLabel
                            key={index}
                            onClick={(e) => handleClick(direction.value)}
                            label={t(direction.label, {ns: "tutorial/tutorial"})}
                            control={
                                <CustomRadio key={index} name="direction"
                                             value={direction.value}/>
                            }/>
                    ))}
                </RadioGroupInput>
        </Stack>
    )
};

export default FormDirectionConfiguration;
