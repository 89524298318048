// ----------------------------------------------------------------------

function pxToRem(value: any) {
  return `${value / 16}rem`;
}

export default function Typography(theme: any) {
  return {
    MuiTypography: {
      styleOverrides: {
        paragraph: {
          marginBottom: theme.spacing(2),
        },
        gutterBottom: {
          marginBottom: theme.spacing(1),
        },
      },
    },
  };
}
