import {tap} from "rxjs";
import http from "./config/http.service";
import {toast} from "react-toastify";
import tutorialService from "./tutorial.service";
import makeService from "./make.service";
import commentService from "./comment.service";
import reportService from "./report.service";
import channel from "../_emptyEntity/channel";
import channelService from "./channel.service";
import targetService from "./target.service";
import historyService from "./history.service";
import tagService from "./tag.service";
import ratingService from "./rating.service";
import subStepService from "./subStep.service";
import stepService from "./step.service";
import materialService from "./material.service";
import feedbackService from "./feedback.service";
import favorite from "../components/common/targetable/Favorite";
import like from "../components/common/targetable/Like";
import follow from "../components/common/targetable/Follow";
import categoryService from "./category.service";

class utilsService {
    static baseUrl = process.env.REACT_APP_BASE_URL;
    private static token: string = localStorage.getItem('API_TOKEN') ?? "";


    static sleep(delay = 0) {
        return new Promise((resolve) => {
            setTimeout(resolve, delay);
        });
    }

    static checkIsNotSameOldPassword(password: string | null) {
        const res: any = http.post("/check-is-same-password", {password: password})
            .pipe(
                tap((e: any) => {
                    return e;
                }))
        return res //FIXME doit retourner "errors.old_pwd_same" ou true
    }

    static delete = (id: string, entityName: string, t: any, targetable?: string, targetableId?: string) => {
                    switch (entityName) {
                        case'tutorial':
                            tutorialService.delete(id, t);
                            break;
                        case'step':
                            stepService.delete(id,targetableId?? '', t);
                            break;
                        case'rating':
                            ratingService.delete(id, t);
                            break;
                        case'subStep':
                            subStepService.delete(id, targetableId ?? '', t);
                            break;
                        case'make':
                            makeService.delete(id, t);
                            break;
                        case'channel':
                            channelService.delete(id, t);
                            break;
                        case'material':
                            materialService.delete(id, t);
                            break;
                        case'favorite':
                            targetService.delete(id, 'favorites', t);
                            break;
                        case'follow':
                            targetService.delete(id, 'follows', t);
                            break;
                        case'likes':
                            targetService.delete(id, 'likes', t);
                            break;
                        case'history':
                            historyService.delete(id, t);
                            break;
                        case'feedback':
                            feedbackService.delete(id, t);
                            break;
                        case'comment':
                            commentService.delete(id,targetable??'', t);
                            break;
                        case'report':
                            reportService.delete(id, t);
                            break;
                        case'tag':
                            tagService.delete(id, t);
                            break;
                        case'category':
                            categoryService.delete(id, t);
                            break;
                    }
    };
}

export default utilsService;
