import React, {FC} from "react";
import PropTypes from "prop-types";
import {Box} from "@mui/material";

const YoutubeEmbed: FC<{embedId: string, height: number}> = ({embedId, height}) => (
    <Box sx={{height}}>
        <iframe
            title="YouTube video player"
            src={`https://www.youtube.com/embed/${embedId}`}
            className="youtubeEmbed"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
        />
    </Box>
);
// height 520
YoutubeEmbed.propTypes = {
    embedId: PropTypes.string.isRequired
};

export default YoutubeEmbed;