import React, {FC, useEffect, useState, useTransition} from 'react';
import {Grid, Paper, Typography} from "@mui/material";
import {OutlineBorderPaper} from "../../../../../_styles/PaperStyle";
import MaterialTargetService from "../../../../../services/materialTarget.service";
import MaterialService from "../../../../../services/material.service";
import {Tutorial} from "../../../../../@types/tutorial";
import {useTranslation} from "react-i18next";
import MaterialTargetCard from "../../../../common/targetable/materialTarget/MaterialTargetCard";
import MaterialSkeleton from "../../../../layout/skeleton/MaterialSkeleton";
import {SubStep} from "../../../../../@types/subStep";
import {Make} from "../../../../../@types/make";
import AddMaterial from "../../../../material/AddMaterial";

const MaterialSubStepSection: FC<{subStep: SubStep }> = ({ subStep}) => {
    const {t} = useTranslation();
    const targetable = "sub_steps"
    const [subStepMaterials, setSubStepMaterials] = useState<any>([]);
    const [loading, startTransition] = useTransition();

    useEffect(() => {
        startTransition(() => {
            MaterialTargetService.getMaterialSubSteps(subStep.id, t)
        })
        const materialTarget = MaterialTargetService._materialSubSteps.subscribe((e: any) => setSubStepMaterials(e[subStep.id]?.materials));

        return () => {
            materialTarget.unsubscribe();
        };
    }, [subStep.id, t]);

    return (
        <Grid item xs={12}>
            <OutlineBorderPaper>
                <Typography variant="h2" component="legend">
                    {t("edit", {ns: "glossary"}) + ' '
                        + t("un", {ns: "glossary"}) + ' '
                        + t("material", {ns: "glossary"}).toLowerCase()}
                </Typography>
                <Paper>
                    <Grid container>
                        <Grid xs={12} item>
                            <AddMaterial targetable={targetable} subStep={subStep}/>
                        </Grid>
                            {!loading && subStepMaterials ?
                                subStepMaterials.length > 0 ?
                                    subStepMaterials?.map((targetMaterial: any, index: any) => (
                                    <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                                        <MaterialTargetCard
                                            materialTarget={targetMaterial}
                                            targetable={targetable}
                                            targetableId={subStep.id}
                                        />
                                    </Grid>
                                ))
                                    : <Typography variant={"h4"}> Il n'y a pas de matériels associés.</Typography>
                                :
                            <MaterialSkeleton/>}
                    </Grid>
                </Paper>
            </OutlineBorderPaper>
        </Grid>
    );
};

export default MaterialSubStepSection;
