import {BehaviorSubject} from "rxjs";
import {Step} from "../@types/step";
import http from "./config/http.service";
import {Collection} from "../@types/collection";
import stepEntity from "../_emptyEntity/step";
import collection from "../_emptyEntity/Collection";
import {toast} from "react-toastify";
import tutorial from "../_emptyEntity/tutorial";

class stepService {
    static baseUrl = process.env.REACT_APP_BASE_URL;
    static steps: Collection = collection;
    static _steps = new BehaviorSubject<Collection>(this.steps);
    static step: Step = stepEntity;
    static _step = new BehaviorSubject<Step>(this.step);
    private static token: string = localStorage.getItem('API_TOKEN') ?? "";

    static reset = () => {
        this._steps.next(this.steps);
    }

    static getCollection = (tutorialId:string, t: any) => {
        http.get(this.baseUrl +'tutorials/' + tutorialId +'/steps', {headers: {Authorization: this.token}})
            .subscribe({
                next: (e: any) => {
                    this.steps = e.data.steps;
                    this._steps.next(this.steps);
                },
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"})),
            });
    };
    static post = (data: Partial<Step>, tutorialId:string, t: any) => {
        const postData = {...data, number: Number(data.number)}
        http.post(this.baseUrl + `steps`, postData, {headers: {Authorization: this.token}})
            .subscribe({
                next: (e: any) => {
                    this.step = {...stepEntity, ...e.data};
                    this._step.next(this.step);
                    this.getCollection(tutorialId, t)

                },
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"})),
                complete: () => toast.success(t("step", {ns: "glossary"}) + t("success.created", {ns: "utils/notification"}))
            });
    };
    static get = (id: string | undefined, t: any) => {
        http.get(this.baseUrl + `steps/${id}`, {headers: {Authorization: this.token}})
            .subscribe({
                next: (e: any) => {
                    this.step = {...stepEntity, ...e.data};
                    this._step.next(this.step);
                },
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"})),
            });
    };

    static put = (id: string | undefined, data: Partial<Step>, tutorialId: string, t: any) => {
        const postData = {...data, number: Number(data.number)}
        http.put(this.baseUrl + `steps/${id}`, postData, {headers: {Authorization: this.token}})
            .subscribe({
                next: (e: any) => {
                    this.step = {...stepEntity, ...e.data};
                    this._step.next(this.step);
                    this.getCollection(tutorialId, t)
                },
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"})),
                complete: () => toast.success(t("step", {ns: "glossary"}) + t("success.modified", {ns: "utils/notification"}))
            });
    };

    static delete = (id: string, tutorialId:string, t: any) => {
        http.delete(this.baseUrl + `steps/${id}`, {headers: {Authorization: this.token}})
            .subscribe({
                next: () => this.getCollection(tutorialId, t),
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"})),
                complete: () => toast.success(t("step", {ns: "glossary"}) + t("success.deleted", {ns: "utils/notification"}))
            });
    };
}

export default stepService;