import React from 'react';
import {Box, Grid, Paper} from "@mui/material";
import ItemSkeleton from "./ItemSkeleton";

const MaterialSkeleton = () => {
    return (
        <>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <ItemSkeleton/>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <ItemSkeleton/>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <ItemSkeleton/>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <ItemSkeleton/>
            </Grid>
        </>
    );
};

export default MaterialSkeleton
