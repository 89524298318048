import React from 'react';
import {Card, CardContent, Skeleton} from "@mui/material";

const ItemSkeleton = () => {
    return (
        <Card sx={{maxWidth: 345, m: 2, height: 250}}>
            <Skeleton sx={{height: 190, width: 310}} animation="wave" variant="rectangular"/>
            <CardContent>
                <React.Fragment>
                    <Skeleton animation="wave" height={10} style={{marginBottom: 6}}/>
                    <Skeleton animation="wave" height={10} width="80%"/>
                    <Skeleton animation="wave" height={10} style={{marginBottom: 6}}/>
                    <Skeleton animation="wave" height={10} width="80%"/>
                </React.Fragment>
            </CardContent>
        </Card>
    );
};

export default ItemSkeleton;
