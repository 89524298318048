import React from 'react';
import {Button, Stack, Typography} from "@mui/material";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import targetService from "../../../services/target.service";
import {Target} from "../../../@types/target";
import {ta} from "date-fns/locale";
import {useTheme} from "@mui/material/styles";
import FavoriteIcon from "@mui/icons-material/Favorite";
import {useTranslation} from "react-i18next";

const FavoriteButton: React.FC<{ targetable: any | undefined, target: string }> = ({targetable, target}) => {
    const theme = useTheme();
    const {t} = useTranslation()

    const onclick = () => {
        (targetable.isFavorite)?
            targetService.deleteInItem(targetable?.isFavorite, 'favorites', target,['favoriteNumber', 'isFavorite'], t):
            targetService.post({"targetIri": `/api/${target}/${targetable?.id}`}, "favorites", target,['favoriteNumber', 'isFavorite'], t)
    }

    return (
        <Button onClick={onclick} sx={{color:(targetable?.isFavorite)?theme.palette.primary.main: 'black'}}>
            <Stack direction="row" spacing={2}>
                <Typography variant="h4">{targetable?.favoriteNumber}</Typography><FavoriteIcon/>
            </Stack>
        </Button>
    );
};

export default FavoriteButton;
