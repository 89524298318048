import React, {FC, useEffect, useState, useTransition} from 'react';
import {Grid, Paper, Typography} from "@mui/material";
import {OutlineBorderPaper} from "../../../../_styles/PaperStyle";
import MaterialTargetService from "../../../../services/materialTarget.service";
import {Tutorial} from "../../../../@types/tutorial";
import {useTranslation} from "react-i18next";
import MaterialTargetCard from "../../../common/targetable/materialTarget/MaterialTargetCard";
import MaterialSkeleton from "../../../layout/skeleton/MaterialSkeleton";
import {SubStep} from "../../../../@types/subStep";
import {Make} from "../../../../@types/make";

const MaterialSection: FC<{tutorial: Tutorial }> = ({tutorial}) => {
    const {t} = useTranslation();
    const targetable = "tutorials"
    const [targetMaterials, setTargetMaterials] = useState<any>([]);
    const [loading, startTransition] = useTransition();

    useEffect(() => {
        startTransition(() => {
            MaterialTargetService.getCollection(tutorial.id, targetable, t)
        })
        const materialTarget = MaterialTargetService._targetMaterials.subscribe((e: any) => setTargetMaterials(e));

        return () => {
            materialTarget.unsubscribe();
        };
    }, [t, tutorial.id]);

    return (
        <Grid item xs={12}>
            <OutlineBorderPaper>
                <Typography variant="h2" component="legend">
                    {t("edit", {ns: "glossary"}) + ' '
                        + t("un", {ns: "glossary"}) + ' '
                        + t("material", {ns: "glossary"}).toLowerCase()}
                </Typography>
                <Paper>
                    <Grid container>
                            {!loading && targetMaterials ?
                                targetMaterials.length > 0 ?
                                targetMaterials?.map((targetMaterial: any, index: any) => (
                                    <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                                        <MaterialTargetCard
                                            materialTarget={targetMaterial}
                                            targetable={targetable}
                                            targetableId={tutorial.id}
                                        />
                                    </Grid>
                                ))
                                    : <Typography variant={"h4"}> Il n'y a pas de matériels associés.</Typography>
                                :
                            <MaterialSkeleton/>}
                    </Grid>
                </Paper>
            </OutlineBorderPaper>
        </Grid>
    );
};

export default MaterialSection;
