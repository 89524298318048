import React, {useEffect, useState, useTransition} from "react";
import {Box, IconButton, Input, MenuItem, Modal, Stack, Typography} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {Resolver, SubmitHandler, useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import useFormHook from '../../hooks/FormHook'
import TextInput from "../common/formInputs/TextInput";
import {CustomButton} from "../../_styles/ButtonStyle";
import {Feedback} from "../../@types/feedback";
import feedbackService from "../../services/feedback.service";
import RadioGroupInput from "../common/formInputs/RadioGroupInput";
import RadioInput from "../common/formInputs/RadioInput";
import SelectInput from "../common/formInputs/SelectInput";
import {ModalBox} from "../../_styles/ModalStyle";
import {moods, types} from "../../_data/FeedbackData";

const FormAddFeedback: React.FC<{ openAction: boolean, setOpenAction: any }> = ({openAction, setOpenAction}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [loading, startTransition] = useTransition();

    const url = window.location.href

    const resolver: Resolver<Feedback> = async (values) => {
        let errors = {};
        // Validate all fields on submit event
        errors = Object.entries(values).reduce(
            (acc, [name, value]) => (value !== "duration") ? useFormHook.hasError(name, value, acc, t) : null, {});
        console.log(errors)
        return {values, errors};
    };
    const {register, handleSubmit, watch, setError, formState: {errors}} = useForm<Feedback>({
        resolver,
        mode: "onBlur"
    });
    const onSubmit: SubmitHandler<Feedback> = data => {
        const postData = {...data, mood: Number(data.mood)}
        console.log('data', postData, errors);
        if (Object.keys(errors).length === 0) {
            startTransition(() => {
                feedbackService.post(postData, t)
            })
        }
        handleClose()
    };

    const [open, setOpen] = React.useState(openAction);
    const handleClose = () => {
        setOpen(false);
        setOpenAction(false)
    }

    useEffect(() => {
        setOpen(openAction)
    }, [openAction]);

    return (
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ModalBox>
                    <Stack direction="row" justifyContent="flex-end">
                        <IconButton onClick={handleClose} style={{fontSize: "normal"}}>
                            <CloseIcon fontSize="large"/>
                        </IconButton>
                    </Stack>
                    <Typography id="feedback-modal-title" variant="h3" component="h2" style={{textAlign: "center"}}>
                        {t("add", {ns: "glossary"}) + ' '
                            + t("un", {ns: "glossary"}) + ' '
                            + t("feedback", {ns: "glossary"}).toLowerCase()}
                    </Typography>
                    <Box
                        component="form"
                        sx={{'& .MuiTextField-root': {m: 1, width: '25ch'}}}
                        noValidate
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <Stack direction='column' sx={{width: '100%'}}>
                            <Input sx={{position:'unset'}} type="hidden" value={url} {...register('currentUrl')}/>
                            <RadioGroupInput ariaLabelledBy="radio-buttons-group-label" name="mood" helperText={errors.mood} error={errors.mood}>
                                {moods.map((type, index) => (
                                    <RadioInput key={index} value={type.value} name="mood" register={register} label={type.label}/>
                                ))}
                                </RadioGroupInput>

                            <br/>
                            <SelectInput name="type" register={register} required={true} error={errors?.type}
                                         helperText={errors.type} label="type" id="select-standard"  variantFormControl="standard" value={watch("type")??''}>
                                {types.map((type, index) => (
                                <MenuItem key={index} value={type.value}>{t(type.label,{ns:"common/feedback"})}</MenuItem>
                                ))}
                            </SelectInput>
                            <TextInput name='message' register={register} required error={errors.message} multiline maxRows={3}
                                       helperText={errors.message?.message} label={t("label.common.content", {ns:"common/form"})}/>
                        </Stack>
                        <div style={{marginTop: "35px"}}>
                            <CustomButton disabled={loading} variant="contained" type="submit">
                                {t("add", {ns: "glossary"})}
                            </CustomButton>
                        </div>
                    </Box>
                </ModalBox>
            </Modal>
    )
}

export default FormAddFeedback