import * as React from 'react';
import {FC, useEffect, useState} from 'react';
import {useTheme} from '@mui/material/styles';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {ListItem} from "@mui/material";
import {DrawerBase} from "../../../_styles/HeaderStyle";
import {useNavigate} from "react-router-dom";
import {
    Favorite,
    Handyman,
    History,
    ReceiptLong,
    School,
    Subscriptions,
    SyncAlt,
    Twitter,
    Grade,
    BarChart,
    Tag,
    Category
} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import ContextService from "../../../services/context.service";

const defaultMenu = [
    {navigation: "tutorials", label: "default_menu.tutorials", icon: <School/>},
    {navigation: "my-makes", label: "default_menu.makes", icon: <Handyman/>},
    {navigation: "my-likes", label: "default_menu.likes", icon: <Grade/>},
    {navigation: "my-favorites", label: "default_menu.favorites", icon: <Favorite/>},
    {navigation: "my-follows", label: "default_menu.follows", icon: <Twitter/>},
    {navigation: "my-materials", label: "default_menu.materials", icon: <ReceiptLong/>},
    {navigation: "my-history", label: "default_menu.history", icon: <History/>},
];

const workroomMenu = [
    {navigation: "workroom", label: "workroom_menu.workroom", icon: <School/>},
    {navigation: "statistics", label: "workroom_menu.statistics", icon: <BarChart/>},
];

const adminMenu = [
    {navigation: "dashboard", label: "admin_menu.dashboard", icon: <School sx={{color: '#9d62de'}}/>},
    {navigation: "tutorials", label: "admin_menu.tutorials", icon: <School sx={{color: '#9d62de'}}/>},
    {navigation: "makes", label: "admin_menu.makes", icon: <Handyman sx={{color: '#9d62de'}}/>},
    {navigation: "channels", label: "admin_menu.channels", icon: <Subscriptions sx={{color: '#9d62de'}}/>},
    {navigation: "materials", label: "admin_menu.materials", icon: <ReceiptLong sx={{color: '#9d62de'}}/>},
    {navigation: "feedbacks", label: "admin_menu.feedbacks", icon: <Favorite sx={{color: '#9d62de'}}/>},
    {navigation: "reports", label: "admin_menu.reports", icon: <Twitter sx={{color: '#9d62de'}}/>},
    {navigation: "persons", label: "admin_menu.persons", icon: <History sx={{color: '#9d62de'}}/>},
    {navigation: "tags", label: "admin_menu.tags", icon: <Tag sx={{color: '#9d62de'}}/>},
    {navigation: "categories", label: "admin_menu.category", icon: <Category sx={{color: '#9d62de'}}/>},
];

const Drawer: FC<{ handleDrawerToggle: any, open: boolean }> = ({handleDrawerToggle, open}) => {
    const theme = useTheme();
    const [isAdmin, setIsAdmin] = useState<boolean>(Boolean(localStorage.getItem('isAdmin')));
    const [isWorkroomContext, setIsWorkroomContext] = useState<boolean>(Boolean(localStorage.getItem('isWorkroomContext')));
    const {t} = useTranslation();
    const navigate = useNavigate();
    const onclick = (navigation: string) => {
        navigate(navigation)
    }

    useEffect(() => {
        console.log('render Drawer')
        const isAdmin = ContextService._isAdmin.subscribe((e: any) => setIsAdmin(e))
        const isWorkroomContext = ContextService._isWorkroomContext.subscribe((e: any) => setIsWorkroomContext(e))
        return () => {
            isAdmin.unsubscribe();
            isWorkroomContext.unsubscribe();
        };
    }, [isAdmin, isWorkroomContext]);

    const menu = (isWorkroomContext) ? workroomMenu : ((isAdmin) ? adminMenu : defaultMenu);

    return (
        <DrawerBase variant="permanent" open={open} sx={{color: 'purple'}}>
            <List>
                {menu.map((item, index) => (
                    <ListItem key={item.label} disablePadding sx={{display: 'block'}}>
                        <ListItemButton onClick={() => onclick(item.navigation)}
                                        sx={{minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5}}>
                            <ListItemIcon sx={{minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center'}}>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText primary={t(item.label, {ns: "header/sideBar"})} sx={{opacity: open ? 1 : 0}}/>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Divider/>
            <List>
                <ListItemButton sx={{minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5}}
                                onClick={() => ContextService.setIsAdmin(!isAdmin)}>
                    <ListItemIcon
                        sx={{minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center'}}><SyncAlt/></ListItemIcon>
                    <ListItemText primary="switch context" sx={{opacity: open ? 1 : 0}}/>
                </ListItemButton>
            </List>
        </DrawerBase>
    );
}
export default Drawer;