import React, {FC, useEffect, useTransition} from "react";
import {Box, MenuItem, Stack, Typography} from "@mui/material";
import {CustomButton} from "../../../_styles/ButtonStyle";
import TextInput from "../../common/formInputs/TextInput";
import {useTranslation} from "react-i18next";
import {Resolver, SubmitHandler, useForm} from "react-hook-form";
import {Tutorial, TutorialJson} from "../../../@types/tutorial";
import useFormHook from "../../../hooks/FormHook";
import tutorialService from "../../../services/tutorial.service";
import {costs, difficulties, PEGIs} from "../../../_data/TutorialData";
import SelectInput from "../../common/formInputs/SelectInput";

const FormEditTutorial: FC<{ tutorial: Tutorial }>
    = ({tutorial}) => {
    const {t} = useTranslation();
    const [loading, startTransition] = useTransition();

    const resolver: Resolver<TutorialJson> = async (values) => {
        let errors = {};
        // Validate all fields on submit event
        errors = Object.entries(values).reduce(
            (acc, [name, value]) => (value !== "duration") ? useFormHook.hasError(name, value, acc, t) : null, {});
        console.log(errors)
        return {values, errors};
    };
    const {register, handleSubmit, reset, watch, setError, formState: {errors}} = useForm<TutorialJson>({
        resolver,
        mode: "onBlur"
    });
    useEffect(() => {
        reset(
            {
                title: tutorial.title,
                description: tutorial.description,
                cost: tutorial.cost,
                difficulty: tutorial.difficulty,
                PEGI: tutorial.PEGI
            }
        )
    }, [tutorial]);


    const onSubmit: SubmitHandler<TutorialJson> = data => {
        console.log('data', data, errors);
        if (Object.keys(errors).length === 0) {
            startTransition(() => {
                tutorialService.put(tutorial.id, data, t)
            })
        }
    };

    return (
        <Box p={2}>
            {!loading &&
            <Box
                component="form"
                sx={{'& .MuiTextField-root': {m: 1, width: '25ch'}}}
                noValidate
                onSubmit={handleSubmit(onSubmit)}
            >
                <Stack spacing={2} direction='column' sx={{width: '100%'}}>
                    <TextInput name='title' register={register} required
                               helperText={errors?.title?.message}
                               defaultValue={tutorial.title}
                               label={t("label.common.title", {ns: "common/form"})}/>
                    <TextInput name='description' register={register} required multiline
                               helperText={errors?.description?.message}
                               defaultValue={tutorial.description}
                               label={t("label.common.description", {ns: "common/form"})}/>

                    <SelectInput name="cost" register={register} required={true} error={errors?.cost}
                                 helperText={errors.cost} label={t("label.tutorial.cost", {ns: "common/form"})} id="select-standard"
                                 variantFormControl="standard" value={watch("cost") ?? tutorial.cost}>
                        {costs.map((cost, index) => (
                            <MenuItem key={index}
                                      value={cost.value}>{t(cost.label, {ns: "tutorial/tutorial"})}</MenuItem>
                        ))}
                    </SelectInput>
                    <SelectInput name="difficulty" register={register} required={true} error={errors?.difficulty}
                                 helperText={errors.difficulty} label={t("label.tutorial.difficulty", {ns: "common/form"})} id="select-standard"
                                 variantFormControl="standard" value={watch("difficulty") ?? tutorial.difficulty}>
                        {difficulties.map((difficulty, index) => (
                            <MenuItem key={index} value={difficulty.value}>{t(difficulty.label, {ns: "tutorial/tutorial"})}</MenuItem>
                        ))}
                    </SelectInput>
                    <SelectInput name="PEGI" register={register} required={true} error={errors?.PEGI}
                                 helperText={errors.PEGI} label={t("label.tutorial.PEGI", {ns: "common/form"})} id="select-standard"
                                 variantFormControl="standard" value={watch("PEGI", tutorial.PEGI)}>
                        {PEGIs.map((PEGI, index) => (
                            <MenuItem key={index}
                                      value={PEGI.value}>{t(PEGI.label, {ns: "tutorial/tutorial"})}</MenuItem>
                        ))}
                    </SelectInput>


                </Stack>
                <div style={{marginTop: "35px"}}>
                    <CustomButton //disabled={loading}
                        variant="contained" type="submit">
                        {t("edit", {ns: "glossary"})}
                    </CustomButton>
                </div>
            </Box>
            }
        </Box>
    )
}

export default FormEditTutorial