import {Box, Card, CardContent, Container} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
// @ts-ignore
import Page from "../../../components/layout/Page";

const CgvPage = () => {
    const {t} = useTranslation("translation");
    return (
        <Page title={t("cgv.title")}>
            <Container>
                <Card>
                    <CardContent>
                        <Box sx={{maxHeight: '75vh', overflow: 'auto', paddingRight:2.5}}
                             dangerouslySetInnerHTML={{__html: `${t("cgv.content", {ns:""})}`}}
                        ></Box>
                    </CardContent>
                </Card>
            </Container>
        </Page>
    );
};

export default CgvPage;
