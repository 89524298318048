import {BehaviorSubject} from "rxjs";

class contextService {

    static _isAdmin = new BehaviorSubject(false);

    static async setIsAdmin(value: Boolean) {
        let isAdmin: any = await localStorage.getItem('isAdmin')
        //console.log('isAdmin service', isAdmin)
        if (isAdmin === undefined)
            await localStorage.setItem('isAdmin', 'false')
        //console.log('update', value)
        await localStorage.setItem('isAdmin', (value) ? 'true' : "")
        isAdmin = Boolean(localStorage.getItem('isAdmin')) ?? false
        this._isAdmin.next(isAdmin)
    }

    static _isWorkroomContext = new BehaviorSubject(false);

    static async setIsWorkroomContext(value: Boolean) {
        let isWorkroomContext: any = await localStorage.getItem('isWorkroomContext')
        //console.log('service isWorkroomContext',isWorkroomContext)
        if (isWorkroomContext === undefined)
            await localStorage.setItem('isWorkroomContext', "false")
        //console.log('update', value)
        await localStorage.setItem('isWorkroomContext', (value) ? 'true' : "")
        isWorkroomContext = Boolean(localStorage.getItem('isWorkroomContext')) ?? false
        this._isWorkroomContext.next(isWorkroomContext)
    }
}

export default contextService;
