import React, {useState} from "react";
import PasswordRules from "./password/PasswordRules";
import TextInput from "./TextInput";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {IconButton, InputAdornment} from "@mui/material";
import {useTranslation} from "react-i18next";

const PasswordInput: React.FC<{
    name: string,
    label?: string,
    required: boolean,
    value?: any,
    error?: any,
    showRules?: boolean,
    disabled?: boolean,
    register?: any
}>
    = ({
           name,
           label,
           required,
           value,
           error,
           showRules,
           disabled,
           register
       }) => {
    const {t} = useTranslation("label");
    const [showPassword, setShowPassword] = useState(false);

    return (<>
            <TextInput
                type={showPassword ? 'text' : 'password'}
                name={name}
                label={label ?? t(`label.user.password`, {ns: "common/form"})}
                required={required}
                error={error}
                fullWidth
                disabled={disabled}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={(event: any) => event.preventDefault()}
                            edge="end"
                        >
                            {showPassword ? <VisibilityOff/> : <Visibility/>}
                        </IconButton>
                    </InputAdornment>
                }
                helperText={!error && showRules? t("label.password.pwd_rules", {ns: "common/form"}) : error?.message}
                register={register}
            />
            {showRules && <PasswordRules value={value}/>}
        </>
    );
};
export default PasswordInput;