import Person from "./person";
import TagTarget from "./tagTarget";
import mediaObject from "./MediaObject";

const tutorialEntity = {
    "@context": "",
    "@id": "",
    "@type": "",
    "id": "",
    "title": "",
    "description": "",
    "duration": "",
    "cost": "",
    "status": "",
    "difficulty": "",
    "PEGI": "",
    "coverPicture": mediaObject,
    "pictures": [],
    "stepNumber": 0,
    "ratings": [],
    "ratingNumber": 0,
    "averageRating": 0,
    "likeNumber": 0,
    "isLiked": false,
    "favoriteNumber": 0,
    "isFavorite": false,
    "person": Person,
    "pseudo": "",
    "view": 0,
    "steps": [],
    "categories": [],
    "tagTarget": TagTarget,
    "createdAt": "",
    "updatedAt": ""
};
export default tutorialEntity;