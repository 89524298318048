import React, {FC, useEffect, useState, useTransition} from "react";
import {Box, Button, IconButton, Input, MenuItem, Modal, Stack, Typography} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {Resolver, SubmitHandler, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import useFormHook from '../../../../../hooks/FormHook'
import TextInput from "../../../../common/formInputs/TextInput";
import {CustomButton} from "../../../../../_styles/ButtonStyle";
import {ModalBox} from "../../../../../_styles/ModalStyle";
import SelectInput from "../../../../common/formInputs/SelectInput";
import {Step} from "../../../../../@types/step";
import stepService from "../../../../../services/step.service";
import {Tutorial} from "../../../../../@types/tutorial";

const FormAddStep: FC<{ tutorial: Tutorial, openAction: boolean, setOpenAction: any }>
    = ({tutorial, openAction, setOpenAction}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [loading, startTransition] = useTransition();

    const resolver: Resolver<Step> = async (values) => {
        let errors = {};
        // Validate all fields on submit event
        errors = Object.entries(values).reduce(
            (acc, [name, value]) => (value !== "duration") ? useFormHook.hasError(name, value, acc, t) : null, {});
        console.log(errors)
        return {values, errors};
    };
    const {register, handleSubmit, watch, setError, getValues, formState: {errors}} = useForm<Step>({
        resolver,
        mode: "onBlur"
    });

    const onSubmit: SubmitHandler<Step> = data => {
        console.log('data', data, errors);
        if (Object.keys(errors).length === 0) {
            startTransition(() => {
                stepService.post(data, tutorial.id, t)
            })
        }
        handleClose()
    };

    const [open, setOpen] = React.useState(openAction);
    const handleClose = () => {
        setOpen(false);
        setOpenAction(false)
    }

    useEffect(() => {
        setOpen(openAction)
    }, [openAction]);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <ModalBox>
                <Stack direction="row" justifyContent="flex-end">
                    <IconButton onClick={handleClose} style={{fontSize: "normal"}}>
                        <CloseIcon fontSize="large"/>
                    </IconButton>
                </Stack>
                <Typography id="modal-modal-title" variant="h3" component="h2" style={{textAlign: "center"}}>
                    {t("add", {ns: "glossary"}) + ' '
                        + t("a_f", {ns: "glossary"}) + ' '
                        + t("step", {ns: "glossary"}).toLowerCase()}
                </Typography>
                <Box
                    component="form"
                    sx={{'& .MuiTextField-root': {m: 1, width: '25ch'}}}
                    noValidate
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Stack direction='column' spacing={2} sx={{width: '100%'}}>
                        <Input sx={{position: 'unset'}} type="hidden" {...register('tutorial')} required
                               value={tutorial['@id']}/>
                        <TextInput name='label' register={register} required error={errors.label}
                                   helperText={(errors?.label?.message)?t(errors.label.message, {ns:'common/form'}):''}
                                   label={t('label.common.title', {ns: 'common/form'})}/>
                        <SelectInput name='config' value={watch("config") ?? ''} label='Config' error={errors.config}
                                     register={register} helperText={(errors?.config?.message) ? t(errors.config.message, {ns: 'common/form'}) : ''}>
                            <MenuItem value='H'>{t("label.subStep.H", {ns: 'common/form'})}</MenuItem>
                            <MenuItem value='HH'>{t("label.subStep.HH", {ns: 'common/form'})}</MenuItem>
                            <MenuItem value='HV'>{t("label.subStep.HV", {ns: 'common/form'})}</MenuItem>
                            <MenuItem value='V'>{t("label.subStep.V", {ns: 'common/form'})}</MenuItem>
                        </SelectInput>
                        <SelectInput name='number' value={watch("number", '')} label='Number' error={errors.number}
                                     register={register}
                                     helperText={(errors?.number?.message) ? t(errors.number.message, {ns: 'common/form'}) : ''}>
                            <MenuItem value={'1'}>1</MenuItem>
                            <MenuItem value={'2'}>2</MenuItem>
                            <MenuItem value={'3'}>3</MenuItem>
                            <MenuItem value={'4'}>4</MenuItem>
                            <MenuItem value={'5'}>5</MenuItem>
                            <MenuItem value={'6'}>6</MenuItem>
                        </SelectInput>
                    </Stack>
                    <div style={{marginTop: "35px"}}>
                        <Button
                            variant="contained"
                            onClick={handleClose}
                            style={{backgroundColor: "crimson"}}
                        >Annuler</Button>
                        <CustomButton disabled={loading} variant="contained" type="submit">
                            {t("add", {ns: 'glossary'})}
                        </CustomButton>
                    </div>
                </Box>
            </ModalBox>
        </Modal>
    )
}

export default FormAddStep