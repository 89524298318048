import {FC, useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {useTranslation} from "react-i18next";
import {CircularProgress, Grid} from '@mui/material';
import {CustomButton} from "../../../_styles/ButtonStyle";

function sleep(delay = 0) {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
}

const AutoCompleteInput: FC<{
    collection: readonly any[],
    setValues: any,
    addAction: any,
    placeholder: string
}> = ({collection, setValues, addAction, placeholder}) => {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState<readonly any[]>([]);
    const loading = open && options.length === 0;

    useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            await sleep(1e3); // For demo purposes.

            if (active) {
                setOptions([...collection]);
            }
        })();

        return () => {
            active = false;
        };
    }, [collection, loading]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    return (
        <Grid container>
            <Grid item xs={8}>
                <Autocomplete
                    id="tag-select"
                    sx={{}}
                    open={open}
                    onOpen={() => {setOpen(true);}}
                    onClose={() => {setOpen(false);}}
                    onChange={(event, newValue) => {setValues(newValue);}}
                    multiple
                    limitTags={2}
                    isOptionEqualToValue={(option, value) => (option.name) ? option.name === value.name : option.label === value.label}
                    getOptionLabel={(option) => (option.name) ? option.name : option.label}
                    options={options}
                    loading={loading}
                    autoHighlight
                    renderOption={(props, option) => (
                        <Box key={option.id} component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                            {option.image &&
                            <img src={option.image} alt={(option.name) ? option.name : option.label} width="30"
                                 loading="lazy"/>
                            }
                            {/*<img

                        width="20"
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                        alt=""
                    />*/}
                            {option.name ? option.name : option.label}
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={placeholder}
                            placeholder={placeholder}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                                endadornment: (
                                    <>
                                        {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),

                            }}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={4}>
                <CustomButton variant="contained" onClick={addAction}>{t("validate", {ns: "glossary"})}</CustomButton>
            </Grid>
        </Grid>
    );
}
export default AutoCompleteInput;