const targetEntity = {
    "@context": "",
    "@id": "",
    "@type": "",
    "id": "",
    "person": "",
    "targetIri": "",
    "target": {},
    "createdAt": "",
    "updatedAt": ""
};
export default targetEntity;