import React from 'react';

const WorkroomStatistics = () => {
    return (
        <div>

        </div>
    );
};

export default WorkroomStatistics;
