import React, {FC, useEffect, useState, useTransition} from "react";
import {Box, Button, IconButton, Modal, Stack, Typography} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {Resolver, SubmitHandler, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import useFormHook from "../../../hooks/FormHook";
import TextInput from "../../common/formInputs/TextInput";
import {CustomButton} from "../../../_styles/ButtonStyle";
import {ModalBox} from "../../../_styles/ModalStyle";
import {Category} from "../../../@types/category";
import CategoryService from "../../../services/category.service";
import EditIcon from "@mui/icons-material/Edit";

const FormEditCategory: FC<{category: Category }> = ({category}) => {
    const {t} = useTranslation();
    const [loading, startTransition] = useTransition();
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    }
    const resolver: Resolver<Category> = async (values) => {
        let errors = {};
        // Validate all fields on submit event
        errors = Object.entries(values).reduce(
            (acc, [name, value]) => useFormHook.hasError(name, value, acc, t), {});
        console.log(errors)
        return {values, errors};
    };
    const {register, handleSubmit, watch, setError, getValues, formState: {errors}} = useForm<Category>({
        resolver,
        mode: "onBlur"
    });
    const onSubmit: SubmitHandler<Category> = data => {
        console.log('data', data, errors);
        if (Object.keys(errors).length === 0) {
            startTransition(() => {
                CategoryService.put(category.id, data, t)
            })
        }
        handleClose()
    };

    return (
        <>
            <IconButton onClick={() => setOpen(true)}><EditIcon/></IconButton>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby={`edit-modal-${category.id}`}
                aria-describedby="modal-modal-description"
            >
                <ModalBox>
                    <Stack direction="row" justifyContent="flex-end">
                        <IconButton onClick={handleClose} style={{fontSize: "normal"}}>
                            <CloseIcon fontSize="large"/>
                        </IconButton>
                    </Stack>
                    <Typography id="modal-modal-title" variant="h3" component="h2" style={{textAlign: "center"}}>
                        {t("edit", {ns: "glossary"}) + ' '
                            + t("une", {ns: "glossary"}) + ' '
                            + t("category", {ns: "glossary"}).toLowerCase()}
                    </Typography>
                    <Box
                        component="form"
                        sx={{'& .MuiTextField-root': {m: 1, width: '25ch'}}}
                        noValidate
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <Stack direction='column' spacing={2} sx={{width: '100%'}}>
                            <TextInput name='label' register={register} required error={errors.label}
                                       defaultValue={category.label}
                                       helperText={(errors?.label?.message) ? t(errors.label.message, {ns: 'common/form'}) : ''}
                                       label={t('label.common.name', {ns: 'common/form'})}/>
                            <TextInput name='description' register={register} required error={errors.description}
                                       multiline rows={10} defaultValue={category.description}
                                       helperText={(errors?.description?.message) ? t(errors.description.message, {ns: 'common/form'}) : ''}
                                       label={t('label.common.description', {ns: 'common/form'})}/>
                        </Stack>
                        <div style={{marginTop: "35px"}}>
                            <Button
                                variant="contained"
                                onClick={handleClose}
                                style={{backgroundColor: "crimson"}}
                            >Annuler</Button>
                            <CustomButton disabled={loading} variant="contained" type="submit">
                                {t("edit", {ns: 'glossary'})}
                            </CustomButton>
                        </div>
                    </Box>
                </ModalBox>
            </Modal>
        </>
    )
}

export default FormEditCategory