import React, {FC, useEffect, useState, useTransition} from "react";
import {Box, Button, IconButton, MenuItem, Modal, Stack, Typography} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {Resolver, SubmitHandler, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import useFormHook from "../../../hooks/FormHook";
import TextInput from "../../common/formInputs/TextInput";
import SelectInput from "../../common/formInputs/SelectInput";
import {CustomButton} from "../../../_styles/ButtonStyle";
import {ModalBox} from "../../../_styles/ModalStyle";
import {Category} from "../../../@types/category";
import CategoryService from "../../../services/category.service";

const FormAddCategory: FC<{ openAction: boolean, setOpenAction: any }>
    = ({openAction, setOpenAction}) => {
    const {t} = useTranslation();
    const [loading, startTransition] = useTransition();

    const resolver: Resolver<Category> = async (values) => {
        let errors = {};
        // Validate all fields on submit event
        errors = Object.entries(values).reduce(
            (acc, [name, value]) => useFormHook.hasError(name, value, acc, t), {});
        console.log(errors)
        return {values, errors};
    };
    const {register, handleSubmit, watch, setError, getValues, formState: {errors}} = useForm<Category>({
        resolver,
        mode: "onBlur"
    });
    const onSubmit: SubmitHandler<Category> = data => {
        console.log('data', data, errors);
        if (Object.keys(errors).length === 0) {
            startTransition(() => {
                CategoryService.post(data, t)
            })
        }
        handleClose()
    };

    const [open, setOpen] = useState(openAction);
    const handleClose = () => {
        setOpen(false);
        setOpenAction(false)
    }

    useEffect(() => {
        setOpen(openAction)
    }, [openAction]);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <ModalBox>
                <Stack direction="row" justifyContent="flex-end">
                    <IconButton onClick={handleClose} style={{fontSize: "normal"}}>
                        <CloseIcon fontSize="large"/>
                    </IconButton>
                </Stack>
                <Typography id="modal-modal-title" variant="h3" component="h2" style={{textAlign: "center"}}>
                    {t("add", {ns: "glossary"}) + ' '
                        + t("une", {ns: "glossary"}) + ' '
                        + t("category", {ns: "glossary"}).toLowerCase()}
                </Typography>
                <Box
                    component="form"
                    sx={{'& .MuiTextField-root': {m: 1, width: '25ch'}}}
                    noValidate
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Stack direction='column' spacing={2} sx={{width: '100%'}}>
                        <TextInput name='label' register={register} required error={errors.label}
                                   helperText={(errors?.label?.message) ? t(errors.label.message, {ns: 'common/form'}) : ''}
                                   label={t('label.common.name', {ns: 'common/form'})}/>
                        <TextInput name='description' register={register} required error={errors.description} multiline rows={10}
                                   helperText={(errors?.description?.message) ? t(errors.description.message, {ns: 'common/form'}) : ''}
                                   label={t('label.common.description', {ns: 'common/form'})}/>
                    </Stack>
                    <div style={{marginTop: "35px"}}>
                        <Button
                            variant="contained"
                            onClick={handleClose}
                            style={{backgroundColor: "crimson"}}
                        >Annuler</Button>
                        <CustomButton disabled={loading} variant="contained" type="submit">
                            {t("add", {ns: 'glossary'})}
                        </CustomButton>
                    </div>
                </Box>
            </ModalBox>
        </Modal>
    )
}

export default FormAddCategory