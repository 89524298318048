import Tutorial from "./tutorial";

const stepEntity = {
    "@context": "",
    "@id": "",
    "@type": "",
    "id": "",
    "label":"",
    "number": '0',
    "config": "H",
    "stepNumber": 0,
    "textColor": "",
    "tutorial": Tutorial,
    "subSteps": [],
    "backgroundColor": "",
    "createdAt": "",
    "updatedAt": ""
};
export default stepEntity;