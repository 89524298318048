import React from 'react';
import {Button, Stack, Typography} from "@mui/material";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import targetService from "../../../services/target.service";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";

const LikeButton: React.FC<{ targetable: any | undefined, target: string }> = ({targetable, target}) => {
    const theme = useTheme();
    const {t} = useTranslation()

    const onclick = () => {
        (targetable.isLiked) ?
            targetService.deleteInItem(targetable?.isLiked, 'likes', target, ['likeNumber', 'isLiked'], t) :
            targetService.post({"targetIri": `/api/${target}/${targetable?.id}`}, "likes", target,['likeNumber', 'isLiked'], t);
    }

    return (
        <Button onClick={onclick} sx={{color: (targetable?.isLiked) ? theme.palette.primary.main : 'black'}}>
            <Stack direction="row" spacing={2}>
                <Typography variant="h4">{targetable?.likeNumber}</Typography><ThumbUpAltIcon/>
            </Stack>
        </Button>
    );
};

export default LikeButton;
