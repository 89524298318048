import {BehaviorSubject} from "rxjs";
import {Person} from "../@types/person";
import http from "./config/http.service";
import {Collection} from "../@types/collection";
import personEntity from "../_emptyEntity/person";
import collection from "../_emptyEntity/Collection";
import {toast} from "react-toastify";

class personService {
    static baseUrl = process.env.REACT_APP_BASE_URL;
    static persons: Collection = collection;
    static _persons = new BehaviorSubject<Collection>(this.persons);
    static person: Person = personEntity;
    static _person = new BehaviorSubject<Person>(this.person);
    private static token: string = localStorage.getItem('API_TOKEN') ?? "";

    static getCollection = (t: any, takeNumber?: number) => {
        http.get(this.baseUrl + 'people', {headers: {Authorization: this.token}, params:{take: takeNumber}})
            .subscribe({
                next: (e: any) => {
                    this.persons = e.data["hydra:member"];
                    this._persons.next(this.persons);
                },
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"})),
            });
    };
    static post = (data: Partial<Person>, t: any) => {
        http.post(this.baseUrl + `people`, data, {headers: {Authorization: this.token}})
            .subscribe({
                next: (e: any) => {
                    this.person = {...personEntity, ...e.data};
                    this._person.next(this.person);
                },
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"})),
                complete: () => toast.success(t("person", {ns: "glossary"}) + t("success.created", {ns: "utils/notification"}))
            });
    };
    static get = (id: string | undefined, t: any) => {
        http.get(this.baseUrl + `people/${id}`, {headers: {Authorization: this.token}})
            .subscribe({
                next: (e: any) => {
                    this.person = {...personEntity, ...e.data};
                    this._person.next(this.person);
                },
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"})),
            });
    };

    static put = (id: string | undefined, data: Partial<Person>, t: any) => {
        http.put(this.baseUrl + `people/${id}`, data, {headers: {Authorization: this.token}})
            .subscribe({
                next: (e: any) => {
                    this.person = {...personEntity, ...e.data};
                    this._person.next(this.person);
                },
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"})),
                complete: () => toast.success(t("person", {ns: "glossary"}) + t("success.modified", {ns: "utils/notification"}))
            });
    };

    static delete = (id: string | undefined, t: any) => {
        http.delete(this.baseUrl + `people/${id}`, {headers: {Authorization: this.token}})
            .subscribe({
                next: () => this.getCollection(t),
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"})),
                complete: () => toast.success(t("person", {ns: "glossary"}) + t("success.deleted", {ns: "utils/notification"}))
            });
    };
}

export default personService;