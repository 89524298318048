import {BehaviorSubject} from "rxjs";
import {Comment} from "../@types/comment";
import http from "./config/http.service";
import {Collection} from "../@types/collection";
import collection from "../_emptyEntity/Collection";
import commentEntity from "../_emptyEntity/comment";
import {toast} from "react-toastify";

class commentService {
    static baseUrl = process.env.REACT_APP_BASE_URL;
    static comments: Collection = collection;
    static _comments = new BehaviorSubject<Collection>(this.comments);
    static comment: Comment = commentEntity;
    static _comment = new BehaviorSubject<Comment>(this.comment);

    private static token: string = localStorage.getItem('API_TOKEN') ?? "";

    static getCollection = (targetable: string | undefined, t: any) => {
        http.get(this.baseUrl + 'comments', {headers: {Authorization: this.token}, params: {targetIri: targetable}})
            .subscribe({
                next: (e: any) => {
                    this.comments = e.data["hydra:member"];
                    this._comments.next(this.comments);
                },
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"}))
            });
    };

    static post = (data: Partial<Comment>, tutorial: string| undefined, t: any) => {
        http.post(this.baseUrl + `comments`, data, {headers: {Authorization: this.token}})
            .subscribe({
                next: (e: any) => {
                    this.comment = {...commentEntity, ...e.data};
                    this._comment.next(this.comment);
                    this.getCollection(tutorial, t)
                },
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"})),
                complete: () => toast.success(t("comment", {ns: "glossary"}) + t("success.created", {ns: "utils/notification"}))
            });
    };
    static get = (id: string | undefined, t: any) => {
        http.get(this.baseUrl + `comments/${id}`, {headers: {Authorization: this.token}})
            .subscribe({
                next: (e: any) => {
                    this.comment = {...commentEntity, ...e.data};
                    this._comment.next(this.comment);
                },
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"}))
            });
    };

    static put = (id: string | undefined, data: Partial<Comment>, t: any) => {
        http.put(this.baseUrl + `comments/${id}`, data, {headers: {Authorization: this.token}})
            .subscribe({
                next: (e: any) => {
                    this.comment = {...commentEntity, ...e.data};
                    this._comment.next(this.comment);
                },
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"})),
                complete: () => toast.success(t("comment", {ns: "glossary"}) + t("success.modified", {ns: "utils/notification"}))
            });
    };

    static delete = (id: string | undefined, targetable: string, t: any) => {
        http.delete(this.baseUrl + `comments/${id}`, {headers: {Authorization: this.token}})
            .subscribe({
                next: () => this.getCollection(targetable, t),
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"})),
                complete: () => toast.success(t("comment", {ns: "glossary"}) + t("success.deleted", {ns: "utils/notification"}))
            });
    };

    static incrementTarget(id: string, variableName: string[]) {
        // @ts-ignore
        this.comment = {...this.comment, [variableName[0]]: this.comment[variableName[0]] + 1, [variableName[1]]: id}
        this._comment.next(this.comment);
    }

    static decrementTarget(variableName: string[]) {
        // @ts-ignore
        this.comment = {...this.comment, [variableName[0]]: this.comment[variableName[0]] - 1, [variableName[1]]: false};
        this._comment.next(this.comment);
    }
}

export default commentService;
