import React, {FC, useTransition} from 'react';
import {useTranslation} from "react-i18next";
import {Resolver, SubmitHandler, useForm} from "react-hook-form";
import useFormHook from "../../../../hooks/FormHook";
import stepService from "../../../../services/step.service";
import {Step} from "../../../../@types/step";
import {Box, Grid, IconButton, MenuItem, Stack} from "@mui/material";
import TextInput from "../../../common/formInputs/TextInput";
import SelectInput from "../../../common/formInputs/SelectInput";
import {Save} from "@mui/icons-material";
import {GridRow} from "@mui/x-data-grid";
import DeleteModal from "../../../common/DeleteModal";
import tutorial from "../../../../_emptyEntity/tutorial";

const FormEditStep: FC<{ step: Step, tutorialId:string }>
    = ({step, tutorialId}) => {
    const {t} = useTranslation();
    const [loading, startTransition] = useTransition();

    const resolver: Resolver<Step> = async (values) => {
        let errors = {};
        // Validate all fields on submit event
        errors = Object.entries(values).reduce(
            (acc, [name, value]) => useFormHook.hasError(name, value, acc, t), {});
        console.log(errors)
        return {values, errors};
    };
    const {register, handleSubmit, watch, setError, getValues, formState: {errors}} = useForm<Step>({
        resolver,
        mode: "onBlur"
    });
    const onSubmit: SubmitHandler<Step> = data => {
        console.log('data', data, errors);
        if (Object.keys(errors).length === 0) {
            startTransition(() => {
                stepService.put(step.id, data, tutorialId, t)
            })
        }
    };

    return (
        <Box
            component="form"
            sx={{'& .MuiTextField-root': {m: 1}}}
            noValidate
            onSubmit={handleSubmit(onSubmit)}
        >
            <Grid container alignItems="center" spacing={2}>
                <Grid item md={6} >
                    <Box display="grid">
                <TextInput name='label' register={register} required fullWidth error={errors.label}
                           helperText={(errors?.label?.message) ? t(errors.label.message, {ns: 'common/form'}) : ''}
                           defaultValue={step.label}
                           label={t('label.common.title', {ns: 'common/form'})}/>
                    </Box>
                </Grid>
                <Grid item md={2}>
                <SelectInput name='config' value={watch("config", step.config)} label='Config' error={errors.config}
                             register={register} fullWidth
                             helperText={(errors?.config?.message) ? t(errors.config.message, {ns: 'common/form'}) : ''}>
                    <MenuItem value='H'>{t("label.subStep.H", {ns: 'common/form'})}</MenuItem>
                    <MenuItem value='HH'>{t("label.subStep.HH", {ns: 'common/form'})}</MenuItem>
                    <MenuItem value='HV'>{t("label.subStep.HV", {ns: 'common/form'})}</MenuItem>
                    <MenuItem value='V'>{t("label.subStep.V", {ns: 'common/form'})}</MenuItem>
                </SelectInput>
                </Grid>
                    <Grid item md={2}>
                <SelectInput name='number' value={watch('number', String(step.number))} label='Number'
                             error={errors.number}
                             register={register} fullWidth
                             helperText={(errors?.number?.message) ? t(errors.number.message, {ns: 'common/form'}) : ''}>
                    <MenuItem value={'1'}>1</MenuItem>
                    <MenuItem value={'2'}>2</MenuItem>
                    <MenuItem value={'3'}>3</MenuItem>
                    <MenuItem value={'4'}>4</MenuItem>
                    <MenuItem value={'5'}>5</MenuItem>
                    <MenuItem value={'6'}>6</MenuItem>
                </SelectInput>
                    </Grid>
                <Grid item md={1} justifyContent="center">
                    <Box display="grid">
                <IconButton type="submit" disabled={loading} size="large"><Save/></IconButton>
                    </Box>
                </Grid>
                <Grid item md={1} justifyContent="center">
                    <Box display="grid">
                       <DeleteModal entityName={'step'} id={step.id} targetableId={tutorialId}/>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default FormEditStep;
