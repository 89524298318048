import React, {Suspense, lazy} from "react";
import {useRoutes, Navigate} from "react-router";
import NoMenuLayout from "../layout/NoMenuLayout";
import Loader from "../components/layout/Loader";
import CgvPage from "../pages/body/layout/CgvPage";
import PcPage from "../pages/body/layout/PcPage";

const CguPage = lazy(() => import("../pages/body/layout/CguPage"));
const ForgotPwd = lazy(() => import("../pages/body/auth/ForgotPassword"));
const Login = lazy(() => import("../pages/body/auth/Login"));
const NotFound = lazy(() => import("../pages/body/layout/NotFound"));
const SignupPage = lazy(() => import("../pages/body/auth/Signup2"));

export default function NoAuthRoutes() {

    return useRoutes([
        {
            path: "/",
           element: <NoMenuLayout/>,
            children: [
                {
                    path: "/login",
                    element: (
                        <Suspense fallback={<Loader/>}>
                            <Login/>
                        </Suspense>
                    ),
                },
                {
                    path: "/forgot-password",
                    element: (
                        <Suspense fallback={<Loader/>}>
                            <ForgotPwd/>
                        </Suspense>
                    ),
                },
                {
                    path: "/signup",
                    element: (
                        <Suspense fallback={<Loader/>}>
                            <SignupPage/>
                        </Suspense>
                    ),
                },
                {
                    path: "/cgu",
                    element: (
                        <Suspense fallback={<Loader/>}>
                            <CguPage/>
                        </Suspense>
                    ),
                },
                {
                    path: "/cgv",
                    element: (
                        <Suspense fallback={<Loader/>}>
                            <CgvPage/>
                        </Suspense>
                    ),
                },
                {
                    path: "/pc",
                    element: (
                        <Suspense fallback={<Loader/>}>
                            <PcPage/>
                        </Suspense>
                    ),
                },
                {
                    path: "/404",
                    element: (
                        <Suspense fallback={<Loader/>}>
                            <NotFound/>
                        </Suspense>
                    ),
                },
                {
                    path: "/",
                    element: <Navigate to={"/login"} replace/>,
                },
                {
                    path: "*",
                    element: <Navigate to="/404" replace/>
                },
            ].filter((el: any) => el.path),
        },
        {path: "*", element: <Navigate to="/404" replace/>},
    ]);
};