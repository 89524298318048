import {BehaviorSubject} from "rxjs";
import {Make, MakeJson} from "../@types/make";
import http from "./config/http.service";
import {Collection} from "../@types/collection";
import collection from "../_emptyEntity/Collection";
import makeEntity from "../_emptyEntity/make";
import {toast} from "react-toastify";

class makeService {
    static baseUrl = process.env.REACT_APP_BASE_URL;
    static makes: Collection = collection;
    static _makes = new BehaviorSubject<Collection>(this.makes);
    static make: Make = makeEntity;
    static _make = new BehaviorSubject<Make>(this.make);

    private static token: string = localStorage.getItem('API_TOKEN') ?? "";

    static getCollection = (t: any, tutorial?: any) => {
        http.get(this.baseUrl + 'makes', {headers: {Authorization: this.token}, params: tutorial})
            .subscribe({
                next: (e: any) => {
                    this.makes = e.data["hydra:member"];
                    this._makes.next(this.makes);
                },
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"}))
            });
    };

    static post = (data: Partial<MakeJson>, navigate: any, t: any) => {
        http.post(this.baseUrl + `makes`, data, {headers: {Authorization: this.token}})
            .subscribe({
                next: (e: any) => {
                    this.make = {...makeEntity, ...e.data};
                    this._make.next(this.make);
                    navigate(`/makes/${e.id}`)
                },
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"})),
                complete: () => toast.success(t("make", {ns: "glossary"}) + t("success.created", {ns: "utils/notification"}))
            });
    };
    static get = (id: string | undefined, t: any) => {
        http.get(this.baseUrl + `makes/${id}`, {headers: {Authorization: this.token}})
            .subscribe({
                next: (e: any) => {
                    this.make = {...makeEntity, ...e.data};
                    this._make.next(this.make);
                },
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"}))
            });
    };

    static put = (id: string | undefined, data: Partial<MakeJson>, t: any) => {
        http.put(this.baseUrl + `makes/${id}`, data, {headers: {Authorization: this.token}})
            .subscribe({
                next: (e: any) => {
                    this.make = {...makeEntity, ...e.data};
                    this._make.next(this.make);
                },
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"})),
                complete: () => toast.success(t("make", {ns: "glossary"}) + t("success.modified", {ns: "utils/notification"}))
            });
    };

    static delete = (id: string | undefined, t: any) => {
        http.delete(this.baseUrl + `makes/${id}`, {headers: {Authorization: this.token}})
            .subscribe({
                next: () => this.getCollection(t),
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"})),
                complete: () => toast.success(t("make", {ns: "glossary"}) + t("success.deleted", {ns: "utils/notification"}))
            });
    };

    static incrementTarget(id: string, variableName: string[]) {
        this.make = {...this.make, [variableName[0]]: this.make.likeNumber + 1, [variableName[1]]: id}
        this._make.next(this.make);
    }

    static decrementTarget(variableName: string[]) {
        this.make = {...this.make, [variableName[0]]: this.make.likeNumber - 1, [variableName[1]]: false};
        this._make.next(this.make);
    }
}

export default makeService;
