import {FormControl, FormHelperText, Input, InputLabel} from "@mui/material";
import React, {FC} from "react";

const TextInput: FC<{
    helperText: any,
    autoComplete?: string,
    autoFocus?: boolean,
    classes?: object,
    color?: "primary" | "secondary" | "error" | "info" | "success" | "warning" | undefined,
    defaultValue?: any,
    disabled?: boolean,
    endAdornment?: React.ReactNode,
    error?: any,
    fullWidth?: boolean,
    id?: string,
    inputProps?: object,
    inputRef?: any,
    label?: any,
    margin?: 'dense' | 'none',
    maxRows?: number | string,
    minRows?: number | string,
    multiline?: boolean,
    name?: string,
    onChange?: any,
    notched?: boolean,
    placeholder?: string,
    readOnly?: boolean,
    required?: boolean,
    rows?: number | string,
    startAdornment?: any,
    sx?: Array<object> | object,
    type?: string,
    value?: any,
    register?: any
}> = ({
          autoComplete,
          autoFocus,
          classes,
          color,
          defaultValue,
          disabled,
          endAdornment,
          error,
          fullWidth,
          helperText,
          id,
          inputProps,
          inputRef,
          label,
          margin,
          maxRows,
          minRows,
          multiline,
          name,
          onChange,
          placeholder,
          readOnly,
          required,
          rows,
          startAdornment,
          sx,
          type,
          value,
          register
      }) => {
    return (
        <FormControl variant="standard">
            <InputLabel htmlFor={"standard-adornment-" + label}>{label}</InputLabel>
            <Input
                autoComplete={autoComplete}
                autoFocus={autoFocus}
                classes={classes}
                color={color}
                defaultValue={defaultValue}
                disabled={disabled}
                endAdornment={endAdornment}
                error={Boolean(error)}
                fullWidth={fullWidth}
                id={id}
                inputProps={inputProps}
                inputRef={inputRef}
                margin={margin}
                maxRows={maxRows}
                minRows={minRows}
                multiline={multiline}
                name={name}
                onChange={onChange}
                placeholder={placeholder}
                readOnly={readOnly}
                required={required}
                rows={rows}
                startAdornment={startAdornment}
                sx={sx}
                type={type}
                value={value}
                {...register(name, {required: required})}
            />
            <FormHelperText id="standard-helper-text">{helperText}</FormHelperText>
        </FormControl>
    );
};
export default TextInput;