import {BehaviorSubject} from "rxjs";
import {SubStep, SubStepJson} from "../@types/subStep";
import http from "./config/http.service";
import {Collection} from "../@types/collection";
import subStepEntity from "../_emptyEntity/subStep";
import collection from "../_emptyEntity/Collection";
import {toast} from "react-toastify";
import StepService from "./step.service";
import tutorial from "../_emptyEntity/tutorial";
import tutorialService from "./tutorial.service";

class subStepService {
    static baseUrl = process.env.REACT_APP_BASE_URL;
    static subSteps: Collection = collection;
    static _subSteps = new BehaviorSubject<Collection>(this.subSteps);
    static subStep: SubStep = subStepEntity;
    static _subStep = new BehaviorSubject<SubStep>(this.subStep);
    private static token: string = localStorage.getItem('API_TOKEN') ?? "";

    static reset = () => {
        this._subSteps.next(this.subSteps);
    }

    static getCollection = (stepId:string, t: any) => {
        http.get(this.baseUrl + 'steps/'+stepId+'/sub_steps', {headers: {Authorization: this.token}})
            .subscribe({
                next: (e: any) => {
                    this.subSteps = e.data.subSteps;
                    this._subSteps.next(this.subSteps);
                },
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"})),
            });
    };
    static post = (data: Partial<SubStepJson>, tutorialId: string, t: any) => {
        const postData = {...data, number: Number(data.number)}
        http.post(this.baseUrl + `sub_steps`, postData, {headers: {Authorization: this.token}})
            .subscribe({
                next: (e: any) => {
                    this.subStep = {...subStepEntity, ...e.data};
                    this._subStep.next(this.subStep);
                    StepService.getCollection(tutorialId, t)
                },
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"})),
                complete: () => toast.success(t("subStep", {ns: "glossary"}) + t("success.created", {ns: "utils/notification"}))
            });
    };
    static get = (id: string | undefined, t: any) => {
        http.get(this.baseUrl + `sub_steps/${id}`, {headers: {Authorization: this.token}})
            .subscribe({
                next: (e: any) => {
                    this.subStep = {...subStepEntity, ...e.data};
                    this._subStep.next(this.subStep);
                },
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"})),
            });
    };

    static put = (id: string | undefined, data: Partial<SubStepJson>, tutorialId: string, t: any) => {
        const postData = (data.number)? {...data, number: Number(data.number)}: data;
        http.put(this.baseUrl + `sub_steps/${id}`, postData, {headers: {Authorization: this.token}})
            .subscribe({
                next: (e: any) => {
                    this.subStep = {...e.data};
                    this._subStep.next(this.subStep);
                    StepService.getCollection(tutorialId, t)
                    tutorialService.get(tutorialId, t)
                },
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"})),
                complete: () => toast.success(t("subStep", {ns: "glossary"}) + t("success.modified", {ns: "utils/notification"}))
            });
    };

    static delete = (id: string | undefined, stepId:string, t: any) => {
        http.delete(this.baseUrl + `sub_steps/${id}`, {headers: {Authorization: this.token}})
            .subscribe({
                next: () => this.getCollection(stepId, t),
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"})),
                complete: () => toast.success(t("step", {ns: "glossary"}) + t("success.deleted", {ns: "utils/notification"}))
            });
    };
}

export default subStepService;