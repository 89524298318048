import React, {FC, useTransition} from 'react';
import {SubStep, SubStepJson} from "../../../../../@types/subStep";
import {useTranslation} from "react-i18next";
import stepService from "../../../../../services/subStep.service";
import {Box, Grid, IconButton, Stack, Typography} from "@mui/material";
import {Save} from "@mui/icons-material";
import {Resolver, SubmitHandler, useForm} from "react-hook-form";
import useFormHook from "../../../../../hooks/FormHook";
import TextInput from "../../../../common/formInputs/TextInput";
import {Step} from "../../../../../@types/step";
import subStepService from "../../../../../services/subStep.service";

const FormEditSubStep: FC<{ subStep: SubStep, step: Step, tutorialId: string }>
    = ({subStep, step, tutorialId}) => {
    const {t} = useTranslation();
    const [loading, startTransition] = useTransition();

    const resolver: Resolver<SubStepJson> = async (values) => {
        let errors = {};
        // Validate all fields on submit event
        errors = Object.entries(values).reduce(
            (acc, [name, value]) => (value !== "duration") ? useFormHook.hasError(name, value, acc, t) : null, {});
        console.log(errors)
        return {values, errors};
    };
    const {register, handleSubmit, watch, setError, getValues, formState: {errors}} = useForm<SubStepJson>({
        resolver,
        mode: "onBlur"
    });
    const onSubmit: SubmitHandler<SubStepJson> = data => {
        console.log('data', data, errors);
        if (Object.keys(errors).length === 0) {
            startTransition(() => {
                subStepService.put(subStep.id, data, tutorialId, t)
            })
        }
    };

    return (
        <Box width="100%">
            <Box
                component="form"
                sx={{'& .MuiTextField-root': {m: 1}}}
                noValidate
                onSubmit={handleSubmit(onSubmit)}
            >
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography id="modal-modal-title" variant="h4" component="h4" style={{textAlign: "center"}}>
                        {t("label.common.description", {ns: "common/form"})}
                    </Typography>
                    <IconButton type="submit" disabled={loading}><Save/></IconButton>
                </Stack>
                <Stack direction='column' sx={{width: '100%'}} gap={5}>
                    <Grid container>
                        <Grid item xs={10}>
                            <TextInput name='title' sx={{'.MuiFormControl-root:has(>&)': {display: "block"}}} fullWidth
                                       register={register} required defaultValue={subStep.title}
                                       helperText={errors?.title?.message}
                                       label={t('label.common.name', {ns: 'common/form'})}/>
                        </Grid>
                        <Grid item xs={2}>
                            <TextInput name='number' type="number" register={register} required
                                       defaultValue={subStep.number} helperText={errors?.number?.message}
                                       label='Number'/>
                        </Grid>
                    </Grid>
                    <TextInput name='content' register={register} required multiline fullWidth
                               defaultValue={subStep.content}
                               minRows={10} maxRows={10} helperText={errors?.content?.message} label='Content'/>
                </Stack>
            </Box>
        </Box>
    );
};

export default FormEditSubStep;
