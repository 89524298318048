import React, {FC} from 'react';
import {Stack, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import MoneyIcon from '@mui/icons-material/Money';
const Cost: FC<{ targetable: any | undefined }> = ({targetable}) => {
    const {t} = useTranslation()

    return (
        <Stack direction="row" spacing={1} color={"green"}>
           <MoneyIcon fontSize="large"/>
            {targetable?.cost !== "inexpensive" &&
                <MoneyIcon fontSize = "large"/>
            }
            {targetable?.cost === "expensive" &&
                <MoneyIcon fontSize="large"/>
            }
        </Stack>
    );
};

export default Cost
