import {FC, SyntheticEvent, useState} from 'react';
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import {Step} from "../../../../@types/step";
import {TabPanel} from "../../../common/tabs/TabPanel";
import {Divider, Grid, Icon, Stack} from "@mui/material";
import FormEditSubStep from "./subStep/FormEditSubStep";
import FormDirectionConfiguration from "./subStep/FormDirectionConfiguration";
import PictureGallery from "../../../common/targetable/picture/PictureGallery";
import {CustomButton} from "../../../../_styles/ButtonStyle";
import {AntTab, AntTabs} from "../../../../_styles/TabsStyle";
import AddIcon from "@mui/icons-material/Add";
import FormAddSubStep from "./subStep/modal/FormAddSubStep";
import TargetablePictureManagementModal from "../../../common/targetable/picture/modal/TargetablePictureManagementModal";
import MaterialSubStepSection from "../section/SubStep/MaterialSubStepSection";

const SubStepSection: FC<{ step: Step, tutorialId: string }> = ({step, tutorialId}) => {
    const theme = useTheme()
    const {t} = useTranslation();
    const [value, setValue] = useState(0);
    const [openAddSubStep, setOpenAddSubStep] = useState(false);

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <>
            {step &&
                <>
                    <AntTabs value={value} onChange={handleChange} aria-label="ant example"
                             variant="fullWidth">
                        {step?.subSteps?.length > 0 && step.subSteps.map((subStep, index) => (
                            <AntTab key={index} label={subStep.title}/>
                        ))}
                        <AntTab onClick={() => setOpenAddSubStep(true)} label={<Icon><AddIcon/></Icon>}/>
                    </AntTabs>
                    {step?.subSteps?.map((subStep, index) => (
                        <TabPanel key={index} index={value} value={index}>
                            <Grid container>
                                <Grid item xs={12} md={6} pr={5}>
                                    <PictureGallery pictures={subStep?.pictures} coverPicture={subStep?.coverPicture}/>
                                    <Stack direction="row">
                                        <TargetablePictureManagementModal target={subStep} mediaObject="sub_step_media_objects" updateTargetId={tutorialId}/>
                                        <CustomButton variant="contained">Ajouter un extrait
                                            video</CustomButton>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={6} pl={5}>
                                    <Stack divider={<Divider orientation="horizontal" flexItem/>}
                                           direction="column"
                                           justifyContent="space-between"
                                           alignItems="center"
                                           spacing={0}
                                           >
                                        <FormEditSubStep subStep={subStep} step={step} tutorialId={tutorialId}/>
                                        <FormDirectionConfiguration subStep={subStep} tutorialId={tutorialId}/>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={12} p={5}>
                                    <MaterialSubStepSection subStep={subStep}/>
                                </Grid>
                            </Grid>
                        </TabPanel>
                    ))
                    }
                </>
            }
            <FormAddSubStep step={step}  tutorialId={tutorialId} openAction={openAddSubStep} setOpenAction={setOpenAddSubStep}/>
        </>
    );
};

export default SubStepSection;
