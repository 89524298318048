import {BehaviorSubject} from "rxjs";
import http from "./config/http.service";
import {toast} from "react-toastify";
import {Collection} from "../@types/collection";
import collection from "../_emptyEntity/Collection";
import mediaObjectEntity from "../_emptyEntity/MediaObject";
import {MediaObject} from "../@types/MediaObject";
import tutorialService from "./tutorial.service";
import makeService from "./make.service";
import channelService from "./channel.service";
import materialService from "./material.service";
import commentService from "./comment.service";

class MediaObjectService {
    static baseUrl = process.env.REACT_APP_BASE_URL;
    static targets: Collection = collection;
    static _targets = new BehaviorSubject<Collection>(this.targets);
    static mediaObject: MediaObject = mediaObjectEntity;
    static _target = new BehaviorSubject<MediaObject>(this.mediaObject);

    private static token: string = localStorage.getItem('API_TOKEN') ?? "";

    static getCollection = (targetable: string, t: any) => {
        http.get(this.baseUrl + targetable, {headers: {Authorization: this.token}})
            .subscribe({
                next: (e: any) => {
                    this.targets = e.data["hydra:member"];
                    this._targets.next(this.targets);
                },
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"}))
            });
    };

    static post = (data: FormData, mediaObject: string, entityName:string, targetId:string, t: any, config?: any) => {
        http.post(this.baseUrl + mediaObject, data, {headers: {Authorization: this.token, ...config}})
            .subscribe({
                next: () => {
                    switch (entityName) {
                        case'Tutorial':
                        case'SubStep':
                            tutorialService.get(targetId, t);
                            break;
                        case'Make':
                            makeService.get(targetId, t);
                            break;
                        case'Channel':
                            channelService.get(targetId, t);
                            break;
                        case'Material':
                            materialService.get(targetId, t);
                            break;
                        case'Comment':
                            commentService.get(targetId, t);
                            break;

                    }
                },
                error: (err) => toast.error(err.response.data["hydra:description"]), //toast.error(t("errors.error_append", {ns: "utils/notification"})),
                complete: () => toast.success(t("success.yeah", {ns: "utils/notification"}))
            });
    };

    static put = (id: string | undefined, data: Partial<MediaObject>, mediaObject: string, t: any) => {
        http.put(this.baseUrl + `${mediaObject}/${id}`, data, {headers: {Authorization: this.token}})
            .subscribe({
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"})),
                complete: () => toast.success(t("success.yeah", {ns: "utils/notification"}))
            });
    };

    static delete = (targetable: string, id: string | undefined, t: any) => {
        http.delete(this.baseUrl + `${targetable}/${id}`, {headers: {Authorization: this.token}})
            .subscribe({
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"})),
                complete: () => toast.success(t("success.delete", {ns: "utils/notification"}))
            });
    };

}

export default MediaObjectService;
