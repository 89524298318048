import React, {createContext, FC, useContext, useEffect, useState} from "react";
import {Divider, ListItemIcon, ListItemText, Menu, MenuItem, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import TvIcon from '@mui/icons-material/Tv';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import SettingsIcon from '@mui/icons-material/Settings';
import FeedbackIcon from '@mui/icons-material/Feedback';
import HelpIcon from '@mui/icons-material/Help';
import LogoutButton from "../../../../auth/LogoutButton";
import AddIcon from "@mui/icons-material/Add";
import useObservable from "../../../../../hooks/useObservable";
import {useTheme} from "@mui/material/styles";
import LanguagePopover from "../LanguagePopover";
import FormAddFeedback from "../../../../feedback/FormAddFeedback";
import modeService from "../../../../../services/mode.service";
import ContextService from "../../../../../services/context.service";
import personService from "../../../../../services/person.service";

const ProfileMenu: FC<{
    anchorEl: null | HTMLElement,
    isOpen: boolean,
    handleClose: any,
    tokenData?: any,
    context?: any,
    avatar?: any
}>
    = ({anchorEl, isOpen, handleClose, tokenData, context, avatar}: any) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const {t} = useTranslation();
    const location = useLocation();
    const [contextList, setContextList] = useState<object[]>([]);
    const [openSplashScreen, setOpenSplashScreen] = useState(false);

    const [open, setOpen] = useState(false);
    const [mode, setMode] = useState<'light' | 'dark'> (JSON.parse(localStorage.getItem('parameters') ?? "{}")?.mode ?? 'light');
    const [isWorkroomContext, setIsWorkroomContext] = useState<boolean>(Boolean(localStorage.getItem('isWorkroomContext')));

    const colorMode = () => {
        console.log('mode', mode)
        const newMode = mode === 'dark' ? 'light' : 'dark'
        setMode(newMode);
        modeService.setMode(newMode);
    };
    useEffect(() => {
        console.log('render ProfileMenu')
    const isWorkroomContext = ContextService._isWorkroomContext.subscribe((e: any) => setIsWorkroomContext(e))
    return () => {
        isWorkroomContext.unsubscribe();
    };
    }, [isWorkroomContext]);

    useEffect(() => {
        // tokenData?.roles?.length > 0 && initContextList(tokenData.roles);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tokenData]);

    return (
        <Menu
            sx={{mt: 5}}
            anchorEl={anchorEl}
            anchorOrigin={{vertical: "top", horizontal: "right"}}
            id='user-menu'
            keepMounted
            transformOrigin={{vertical: "top", horizontal: "right"}}
            open={isOpen}
            onClose={handleClose}
        >
            <MenuItem sx={{m: 1}} onClick={() => navigate('/profile')}>
                <ListItemIcon>
                    <AddIcon/>
                </ListItemIcon>
                <ListItemText>
                    <Typography
                        variant="body1"
                        color={location.pathname.includes("profile") ? "primary" : ""}
                        sx={{ml: 2, fontWeight: "600", opacity: location.pathname.includes("profile") ? "1" : "0.5"}}>
                        {t("profile_menu.profile", {ns: "header/appBar"})}
                    </Typography>
                </ListItemText>
            </MenuItem>
            <MenuItem>
                <ListItemIcon>
                    <TvIcon/>
                </ListItemIcon>
                <ListItemText>
                    Créer une chaine / ma chaine
                </ListItemText>
            </MenuItem>
            <MenuItem onClick={() => ContextService.setIsWorkroomContext(!isWorkroomContext)}>
                <ListItemIcon>
                    <AddIcon/>
                </ListItemIcon>
                <ListItemText>
                    {t("profile_menu.workroom", {ns:"header/appBar"})}
                </ListItemText>
            </MenuItem>
            <Divider sx={{mt: 2}}/>
            {/*  <MenuItem>
                <ListItemIcon>
                    <MonetizationOnIcon/>
                </ListItemIcon>
                <ListItemText>
                    Achat
                </ListItemText>
            </MenuItem>*/}
            {/* <MenuItem>
                <ListItemIcon>
                    <AdminPanelSettingsIcon/>
                </ListItemIcon>
                <ListItemText>
                    Vos données
                </ListItemText>
            </MenuItem>
            <Divider sx={{mt: 2}}/>*/}
            <MenuItem onClick={colorMode}>
                <ListItemIcon>
                    {theme.palette.mode === 'dark' ? <LightModeIcon/> : <DarkModeIcon/>}
                </ListItemIcon>
                <ListItemText>
                    {theme.palette.mode === 'dark' ? "Activer mode clair  " : "Activer mode sombre"}
                </ListItemText>
            </MenuItem>
            <LanguagePopover/>
            {/* <MenuItem>
                <ListItemIcon>
                    <AdminPanelSettingsIcon/>
                </ListItemIcon>
                <ListItemText>
                    Mode Restreint
                </ListItemText>
            </MenuItem>
            <MenuItem>
                <ListItemIcon>
                    <LanguageIcon/>
                </ListItemIcon>
                Pays
            </MenuItem>
            <MenuItem>
                <ListItemIcon>
                    <KeyboardIcon/>
                </ListItemIcon>
                <ListItemText>
                    Raccourccis
                </ListItemText>
            </MenuItem>*/}
            <Divider sx={{mt: 2}}/>
            <MenuItem onClick={() => navigate('/faq')}>
                <ListItemIcon>
                    <HelpIcon/>
                </ListItemIcon>
                <ListItemText>
                    {t("profile_menu.help", {ns: "header/appBar"})}
                </ListItemText>
            </MenuItem>
            <MenuItem onClick={() => setOpen(true)}>
                <ListItemIcon>
                    <FeedbackIcon/>
                </ListItemIcon>
                <ListItemText>
                    {t("profile_menu.send_comment", {ns: "header/appBar"})}
                </ListItemText>
            </MenuItem>
            <FormAddFeedback openAction={open} setOpenAction={setOpen}/>
            <MenuItem sx={{m: 1}} onClick={() => navigate('/account/data')}>
                <AdminPanelSettingsIcon/>
                <Typography
                    variant="body1"
                    color={location.pathname.includes("data") ? "primary" : ""}
                    sx={{
                        ml: 2,
                        fontWeight: "600",
                        opacity: location.pathname.includes("data") ? "1" : "0.5",
                    }}
                >
                    {t("profile_menu.data", {ns: "header/appBar"})}
                </Typography>
            </MenuItem>

            {((tokenData?.roles.includes("ROLE_ADMINADVISOR") &&
                    context === "advisor") ||
                (tokenData?.roles.includes("ROLE_ADMINRECRUITER") &&
                    context === "recruiter")) && (
                <MenuItem sx={{m: 1}} onClick={() => navigate('/params')}>
                    <ListItemIcon>
                        <FeedbackIcon/>
                    </ListItemIcon>
                    <Typography
                        variant="body1"
                        color={location.pathname.includes("app/params") ? "primary" : ""}
                        sx={{
                            ml: 2,
                            fontWeight: "600",
                            opacity: location.pathname.includes("app/params") ? "1" : "0.5"
                        }}>
                        {t("profile_menu.admin")}
                    </Typography>
                </MenuItem>
            )}
            <MenuItem sx={{m: 1}} onClick={() => navigate('/account')}>
                <ListItemIcon>
                    <SettingsIcon/>
                </ListItemIcon>
                <Typography
                    variant="body1"
                    color={location.pathname.includes("account/params") ? "primary" : ""}
                    sx={{ml: 2, fontWeight: "600", opacity: location.pathname.includes("account") ? "1" : "0.5"}}>
                    {t("profile_menu.params", {ns: "header/appBar"})}
                </Typography>
            </MenuItem>
            <Divider sx={{mb: 1}}/>
            <LogoutButton/>
        </Menu>
    );
};

export default ProfileMenu;
