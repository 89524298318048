// ----------------------------------------------------------------------

const shape = {
  borderRadiusXs: 4,
  borderRadius6: 6,
  borderRadius: 7,
  borderRadius8: 8,
  borderRadius10: 10,
  borderRadius12: 12,
  borderRadiusSm: 15,
  borderRadiusCard: 15,
  borderRadiusMd: 23,
  borderRadius24: 24,
  borderRadiusPage: 27,
  borderRadius40: 40,
  borderRadius48: 48,
};

export default shape;
