import {FormControl, FormControlLabel, Radio, RadioGroup, styled, Theme} from "@mui/material";
import {NavigateBefore, NavigateNext} from "@mui/icons-material";

export const CustomFormControl = styled(FormControl)
(({theme}: any) => ({
    display: "flex",
    [theme.breakpoints.down('sm')]: {
        marginTop: '24px'
    },
}))
export const CustomRadioGroup = styled(RadioGroup)({
    display: "flex",
    justifyContent: "space-evenly",
    flexWrap: "wrap",
    justifyItems: 'stretch',
    alignContent: 'center',
    width: '100%',
})
export const CustomFormControlLabel = styled(FormControlLabel)
(({theme}: any) => ({
    cursor: "pointer",
    zIndex: "90",
    lineHeight: "1.75em",
    fontSize: "0.875rem",
    fontWeight: "500",
    borderRadius: "8px",
    letterSpacing: "0.02857em",
    border: "1px solid",
    boxSizing: "border-box",
    borderColor: theme.palette.primary.main,
    padding: theme.spacing(1),
    minWidth: "104px",
    width: "50%",
    justifyContent: 'center',
    margin: '0',
    backgroundColor: "transparent",
    color: theme.palette.primary.main,
    transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    transitionDuration: '250ms, 250ms, 250ms',
    transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1)',
    transitionDelay: '0ms, 0ms, 0ms',
    transitionProperty: 'background-color, box-shadow, border',
    "&:hover": {
        backgroundColor: theme.palette.grey[100],
        borderColor: theme.palette.primary.dark,

    },
    "&:active": {
        backgroundColor: theme.palette.grey[400],

    },
    "&:has(.Mui-checked)": {
        backgroundColor: theme.palette.primary.main,
        color: "white",
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)'
    },
    [theme.breakpoints.down('sm')]: {
        width: "100%",
    },
}))

export const CustomRadio = styled(Radio)({
    opacity: "0.011",
    zIndex: "100",
    display: "none"
});

export const Cgu = styled("a")({
    textDecoration: "none",
});

export const BeforeButton = styled(NavigateBefore)(() => ({
    width: '15%',
    height: '100%'
}));
export const NextButton = styled(NavigateNext)(() => ({
    width: '15%',
    height: '100%'
}));

export const FileInput = styled("input")(({theme}: any) => ({
    width: '0.1px',
    height: '0.1px',
    opacity: 0,
    overflow: 'hidden',
    position: 'absolute',
    zIndex: -1,
    'label:has(>&)': {
        fontSize: '1.25em',
        fontWeight: 700,
        color: 'white',
        textAlign:'center',
        backgroundColor: theme.palette.primary.main,
        borderRadius: 10,
        width:200,
        padding:'6px 16px'

    },
    'label:has(>&:focus)': {
        backgroundColor: theme.palette.primary.main,
    },
    'label:hover:has(>&)':{
        backgroundColor: theme.palette.primary.dark,
        cursor: 'pointer' /* "hand" cursor */
    }
}));
