import {BehaviorSubject} from "rxjs";
import {MaterialTarget, MaterialTargetJson} from "../@types/materialTarget";
import http from "./config/http.service";
import {Collection} from "../@types/collection";
import materialTargetEntity from "../_emptyEntity/materialTarget";
import collection from "../_emptyEntity/Collection";
import {toast} from "react-toastify";

class materialTargetService {
    static baseUrl = process.env.REACT_APP_BASE_URL;
    static targetMaterials: Collection = collection;
    static _targetMaterials = new BehaviorSubject<Collection>(this.targetMaterials);
    static materialSubSteps: any = [];
    static _materialSubSteps = new BehaviorSubject<[]>(this.materialSubSteps);

    static targetMaterial: MaterialTarget = materialTargetEntity;
    static _targetMaterial = new BehaviorSubject<MaterialTarget>(this.targetMaterial);

    private static token: string = localStorage.getItem('API_TOKEN') ?? "";

    static reset = () => {
        this._targetMaterials.next(this.targetMaterials);
    }

    static getCollection = (targetableId: string, targetable: string, t: any) => {
        http.get(this.baseUrl + targetable + '/' + targetableId + '/materials', {headers: {Authorization: this.token}})
            .subscribe({
                next: (e: any) => {
                    this.targetMaterials = e.data.materials;
                    this._targetMaterials.next(this.targetMaterials);
                },
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"}))
            });
    };

    static post = (data: Partial<MaterialTargetJson>, targetable: string, targetableId: string, t: any) => {
        http.post(this.baseUrl + `material_targets`, data, {headers: {Authorization: this.token}})
            .subscribe({
                next: (e: any) => {
                    this.targetMaterial = {...materialTargetEntity, ...e.data};
                    this._targetMaterial.next(this.targetMaterial);
                    this.getCollection(targetableId, targetable, t)
                    this.getMaterialSubSteps(targetableId,t)
                },
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"})),
                complete: () => toast.success(t("targetMaterial", {ns: "glossary"}) + t("success.created", {ns: "utils/notification"}))
            });
    };

    static getMaterialSubSteps = (id: string, t: any) => {
        http.get(this.baseUrl + `sub_steps/${id}/materials`, {headers: {Authorization: this.token}})
            .subscribe({
                next: (e: any) => {
                    this.materialSubSteps[id] = {...e.data};
                    this._materialSubSteps.next(this.materialSubSteps);
                },
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"})),
            });
    };

    static put = (id: string | undefined, data: Partial<MaterialTargetJson>, targetable: string, targetableId: string, t: any) => {
        http.put(this.baseUrl + `material_targets/${id}`, data, {headers: {Authorization: this.token}})
            .subscribe({
                next: (e: any) => {
                    this.targetMaterial = {...materialTargetEntity, ...e.data};
                    this._targetMaterial.next(this.targetMaterial);
                    this.getCollection(targetableId, targetable, t)
                    this.getMaterialSubSteps(targetableId, t)
                },
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"})),
                complete: () => toast.success(t("targetMaterial", {ns: "glossary"}) + t("success.modified", {ns: "utils/notification"}))
            });
    };

    static delete = (materialName: string, id: string, targetable: string, targetableId: string, t: any) => {
        http.delete(this.baseUrl + `material_targets/${id}`, {headers: {Authorization: this.token}})
            .subscribe({
                next: () => {
                    this.getCollection(targetableId, targetable, t)
                    this.getMaterialSubSteps(targetableId, t)
                },
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"})),
                complete: () => toast.success(t(`${materialName}`, {ns: "glossary"}) + t("success.deleted", {ns: "utils/notification"}))
            });
    };
}

export default materialTargetService;
