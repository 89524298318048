import Person from "./person";

const historyEntity = {
    "@context": "",
    "@id": "",
    "@type": "",
    "id": "",
    "type": "",
    "targetIri": "",
    "target": {},
    "person": Person,
    "createdAt": "",
    "updatedAt": ""
};
export default historyEntity