import {Card, CardActionArea, CardContent, CardProps, styled} from "@mui/material";
import {alpha} from "@mui/material/styles";

interface StyledCardProps extends CardProps {
    notransform?: boolean
    difficulty?: string
}

export const MyCard = styled(Card)<StyledCardProps>(({theme, notransform = false, difficulty}) => ({
    boxShadow: 'none',
    transition: '0.2s',
    margin: 20,
    ...(difficulty === "easy") && {
        //boxShadow: `0 0 10px 10px ${alpha(theme.palette.success.main, 0.8)}`,
        border: `4px solid ${alpha(theme.palette.success.main, 0.8)}`
    },
    ...(difficulty === "medium") && {
        //boxShadow: `0 0 10px 10px ${alpha(theme.palette.warning.main, 0.5)}`,
        border: `4px solid ${alpha(theme.palette.warning.main, 0.8)}`
    },
    ...(difficulty === "hard") && {
        //boxShadow: `0 0 10px 10px ${alpha(theme.palette.error.main, 0.5)}`,
        border: `4px solid ${alpha(theme.palette.error.main, 0.8)}`
    },
    '&:hover': {
        ...!(notransform) && {
            zIndex: 1,
            boxShadow: `0 0 20px 5px ${alpha(theme.palette.common.black, 0.5)}`,
        }
    },
    [theme.breakpoints.down('xs')]: {
        //     backgroundColor: purple[500],

    },
    [theme.breakpoints.down('sm')]: {
        //     backgroundColor: purple[500],
    },
    [theme.breakpoints.up('sm')]: {
        // maxWidth: "90%",
    },
    [theme.breakpoints.up('md')]: {},
    [theme.breakpoints.up('lg')]: {},

}));
export const preview = {
    width: 600
}
export const list = {
    width: 300
}
export const MyCardActionArea = styled(CardActionArea)(({theme}) => ({

        [theme.breakpoints.down('xs')]:
            {
                //     backgroundColor: purple[500],
            },
        [theme.breakpoints.down('sm')]:
            {
                //     backgroundColor: purple[500],
            },
        [theme.breakpoints.up('sm')]:
            {
                // maxWidth: "90%",
            },
        [theme.breakpoints.up('md')]:
            {},
        [theme.breakpoints.up('lg')]:
            {},
    }))
;
export const MyCardContent = styled(CardContent)(({theme}) => ({
        padding: '0 1rem',
    }))
;