import React, {FC, useContext, useEffect, useState, useTransition} from 'react';
import AutoCompleteInput from "../common/formInputs/AutoCompleteInput";
import {useTranslation} from "react-i18next";
import {PersonContext} from "../../App";
import tutorialService from "../../services/tutorial.service";
import categoryService from "../../services/category.service";
import {Tutorial, TutorialJson} from "../../@types/tutorial";

const AddCategory: FC<{ tutorial: Tutorial }> = ({tutorial}) => {
    const {t} = useTranslation();
    const [values, setValues] = useState<any[] | null>([]);
    const {currentPerson} = useContext(PersonContext);
    const [loading, startTransition] = useTransition();
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        startTransition(() => categoryService.getCollection(t))
        const tags = categoryService._categories.subscribe((e: any) => setCategories(e));
        return () => {
            tags.unsubscribe();
        };
    }, [tutorial]);

    const addCategories = () => {
        let data: Partial<TutorialJson> = {categories: [...tutorial.categories.map((category, index) => category['@id'])]}
        console.log('categories value', values)
        values?.map((value, index) => data.categories?.push(value['@id']))
        console.log('categories', data)
        tutorialService.put(tutorial.id, data, t)
        setValues([])
    }

    return (
        <AutoCompleteInput collection={categories} setValues={setValues} addAction={addCategories}
                           placeholder="Categories"/>
    )
};

export default AddCategory;
