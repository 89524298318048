import MediaObject from "./MediaObject";

const materialEntity = {
    "@context": "",
    "@id": "",
    "@type": "",
    id: "",
    name: "",
    description: "",
    price: 0,
    type:"",
    link: "",
    coverPicture: MediaObject,
    pictures: [],
    createdAt: "",
    updatedAt: ""
};
export default materialEntity;