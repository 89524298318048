import React, {useContext, useEffect, useState, useTransition} from "react";
import {Container, Grid, Paper} from "@mui/material";
import {useTranslation} from "react-i18next";
import Page from "../../../components/layout/Page";
import {LayoutContext} from "../../../layout/FullLayout";
import {useNavigate, useParams} from "react-router-dom";
import {Person} from "../../../@types/person";
import personService from "../../../services/person.service";
import {PersonContext} from "../../../App";
import useObservable from "../../../hooks/useObservable";
import ContextService from "../../../services/context.service";

const Profile = () => {
    const {width} = useContext(LayoutContext);
    const {id} = useParams();
    const {currentPerson} = useContext(PersonContext);
    const isAdmin = useObservable(ContextService._isAdmin)
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [loading, startTransition] = useTransition();
    const [person, setPerson] = useState<Person>();
    const url = window.location.href

    const personId = (id && isAdmin) ? id : currentPerson.id

    useEffect(() => {
        startTransition(() => {
            personService.get(personId, t)
        })
        const person = personService._person.subscribe((e: any) => setPerson(e))
        return () => {
            person.unsubscribe();
        };
    }, []);


    return (

        <Page sx={{ml: width}} title={t("account", {ns: "glossary"})}>
            <Container>
                <Grid container>
                    <Grid item xs={12}>
                        <Paper sx={{}}>
                            <img width="inherit" src={"/assets/images/profile_page_img.png"}
                                 style={{width: '-webkit-fill-available'}} alt="signin_background"/>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Page>

    );
};

export default Profile
