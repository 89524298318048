import React, {FC} from 'react';
import {Box, Rating, Stack, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";

const MyRating: FC<{ targetable: any | undefined }> = ({targetable}) => {
    const {t} = useTranslation()

    return (
        <Box sx={{
          /*  backgroundColor: "rgba(157, 88, 222, 0.5)",*/
            borderRadius: 0.6,
            paddingX:0.4
        }}>
            <Stack direction="row" spacing={1} alignItems="center">
                <Rating name="read-only" value={targetable?.averageRating} readOnly/>
                <Typography variant="h4">({targetable?.ratingNumber})</Typography>
            </Stack>
        </Box>
    );
};

export default MyRating
