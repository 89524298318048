import React, {Suspense, lazy} from "react";
import {useRoutes, Navigate} from "react-router";
import NoMenuLayout from "../layout/NoMenuLayout";
import FullLayout from "../layout/FullLayout";
import Loader from "../components/layout/Loader";
import EditAccount from "../pages/body/user/EditAccount";
import EditProfile from "../pages/body/user/EditProfile";
import Help from "../pages/body/Help";
import MaterialItem from "../pages/body/material/MaterialItem";
import EditPerson from "../pages/body/admin/EditPerson";
import Profile from "../pages/body/user/Profile";
import CategoryManagement from "../pages/body/admin/CategoryManagement";

const WorkroomTutorialEdition = lazy(() => import("../pages/body/workRoom/WorkroomTutorialEdition"));
const LandingPage = lazy(() => import("../pages/body/admin/LandingPage"));
const TutoPage = lazy(() => import("../pages/body/admin/TutoPage"));
const TagManagement = lazy(() => import("../pages/body/admin/TagManagement"));
const Dashboard = lazy(() => import("../pages/body/admin/Dashboard"));
const TutorialManagement = lazy(() => import("../pages/body/admin/TutorialManagement"));
const ChannelManagement = lazy(() => import("../pages/body/admin/ChannelManagement"));
const MakeManagement = lazy(() => import("../pages/body/admin/MakeManagement"));
const PersonManagement = lazy(() => import("../pages/body/admin/PersonManagement"));
const FeedbackManagement = lazy(() => import("../pages/body/admin/FeedbackManagement"));
const ReportManagement = lazy(() => import("../pages/body/admin/ReportManagement"));
const MaterialManagement = lazy(() => import("../pages/body/admin/MaterialManagement"));
const TutorialItem = lazy(() => import("../pages/body/tutorial/TutorialItem"));
const ChannelItem = lazy(() => import("../pages/body/channel/ChannelItem"));
const MakeItem = lazy(() => import("../pages/body/tutorial/MakeItem"));
const NotFound = lazy(() => import("../pages/body/layout/NotFound"));
export default function AdminRoutes() {

    return useRoutes([
        {path: "/", element: <Navigate to="dashboard" replace/>},
        {
            path: "/",
            element: <FullLayout/>,
            children: [
                {
                    path: "/dashboard",
                    element: (
                        <Suspense fallback={<Loader/>}>
                            <Dashboard/>
                        </Suspense>
                    ),
                },
                {
                    path: "/landingpage",
                    element: (
                        <Suspense fallback={<Loader/>}>
                            <LandingPage/>
                        </Suspense>
                    ),
                },
                {
                    path: "/tutopage",
                    element: (
                        <Suspense fallback={<Loader/>}>
                            <TutoPage/>
                        </Suspense>
                    ),
                },
                {
                    path: "/tutorials",
                    element: (
                        <Suspense fallback={<Loader/>}>
                            <TutorialManagement/>
                        </Suspense>
                    ),
                },
                {
                    path: "/tutorials/:id/preview",
                    element: (
                        <Suspense fallback={<Loader/>}>
                            <TutorialItem/>
                        </Suspense>
                    ),
                },
                {
                    path: "/tutorials/:id",
                    element: (
                        <Suspense fallback={<Loader/>}>
                            <WorkroomTutorialEdition/>
                        </Suspense>
                    ),
                },

                {
                    path: "/channels",
                    element: (
                        <Suspense fallback={<Loader/>}>
                            <ChannelManagement/>
                        </Suspense>
                    ),
                },
                {
                    path: "/channels/:id/preview",
                    element: (
                        <Suspense fallback={<Loader/>}>
                            <ChannelItem/>
                        </Suspense>
                    ),
                },
                {
                    path: "/channels/:id",
                    element: (
                        <Suspense fallback={<Loader/>}>
                            <ChannelItem/>
                        </Suspense>
                    ),
                },
                {
                    path: "/makes",
                    element: (
                        <Suspense fallback={<Loader/>}>
                            <MakeManagement/>
                        </Suspense>
                    ),
                },
                {
                    path: "/makes/:id",
                    element: (
                        <Suspense fallback={<Loader/>}>
                            <MakeItem/>
                        </Suspense>
                    ),
                },

                {
                    path: "/persons",
                    element: (
                        <Suspense fallback={<Loader/>}>
                            <PersonManagement/>
                        </Suspense>
                    ),
                },
                {
                    path: "/persons/:id",
                    element: (
                        <Suspense fallback={<Loader/>}>
                            <EditPerson/>
                        </Suspense>
                    ),
                },
                {
                    path: "/profile/:id",
                    element: (
                        <Suspense fallback={<Loader/>}>
                            <Profile/>
                        </Suspense>
                    ),
                },
                {
                    path: "/feedbacks",
                    element: (
                        <Suspense fallback={<Loader/>}>
                            <FeedbackManagement/>
                        </Suspense>
                    ),
                },
                {
                    path: "/reports",
                    element: (
                        <Suspense fallback={<Loader/>}>
                            <ReportManagement/>
                        </Suspense>
                    ),
                },
                {
                    path: "/materials",
                    element: (
                        <Suspense fallback={<Loader/>}>
                            <MaterialManagement/>
                        </Suspense>
                    ),
                },
                {
                    path: "/materials/:id",
                    element: (
                        <Suspense fallback={<Loader/>}>
                            <MaterialItem/>
                        </Suspense>
                    ),
                },
                 {
                    path: "/tags",
                    element: (
                        <Suspense fallback={<Loader/>}>
                            <TagManagement/>
                        </Suspense>
                    ),
                },
                {
                    path: "/categories",
                    element: (
                        <Suspense fallback={<Loader/>}>
                            <CategoryManagement/>
                        </Suspense>
                    ),
                },

                {
                    path: "/account",
                    element: (
                        <Suspense fallback={<Loader/>}>
                            <EditAccount/>
                        </Suspense>
                    ),
                },
                {
                    path: "/profile",
                    element: (
                        <Suspense fallback={<Loader/>}>
                            <EditProfile/>
                        </Suspense>
                    ),
                },
                {
                    path: "404",
                    element: (
                        <Suspense fallback={<Loader/>}>
                            <NotFound/>
                        </Suspense>
                    ),
                },
                {
                    path: "help",
                    element: (
                        <Suspense fallback={<Loader/>}>
                            <Help/>
                        </Suspense>
                    ),
                },
                {path: "*", element: <Navigate to="/404" replace/>},
            ]
        },
        {path: "*", element: <Navigate to="/404" replace/>},
    ]);
};