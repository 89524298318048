import Step from "./step";
import MediaObject from "./MediaObject";

const subStepEntity = {
    "@id": "",
    "@type": "",
    "@context": "",
    id: "",
    step: Step,
    number: '0',
    direction: "",
    title: "",
    content: "",
    coverPicture: MediaObject,
    pictures: [],
    video: "",
    createdAt: "",
    updatedAt: ""
};
export default subStepEntity;