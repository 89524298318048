import React, {FC} from 'react';
import {CardActions, CardMedia, Typography} from "@mui/material";
import {MyCard, MyCardActionArea, MyCardContent} from "../../../_styles/CardStyle";
import LikeButton from "../buttons/LikeButton";
import FavoriteButton from "../buttons/FavoriteButton";
import {Make} from "../../../@types/make";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

const MakeCard: FC <{make: Make}> = ({make}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    return (
            <MyCard>
        <MyCardActionArea onClick={() => navigate(`/makes/${make.id}`)}>
            <CardMedia
                component="img"
                height="194"
                image="https://adala-news.fr/wp-content/uploads/2014/09/ALYS-Voxwave-image-002.jpg"
                alt="Paella dish"
            />
                <MyCardContent>
                    <Typography>By {make.pseudo}</Typography>
                </MyCardContent>
        </MyCardActionArea>
                <CardActions sx={{justifyContent:"space-evenly"}}>
                    <FavoriteButton targetable={make} target="makes"/>
                    <LikeButton targetable={make} target="makes"/>
                </CardActions>
            </MyCard>
    );
};

export default MakeCard;
