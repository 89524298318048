import {BehaviorSubject, Observable} from "rxjs";
import {Channel} from "../@types/channel";
import http from "./config/http.service";
import {Collection} from "../@types/collection";
import collection from "../_emptyEntity/Collection";
import channelEntity from "../_emptyEntity/channel";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
class channelService {
    static baseUrl = process.env.REACT_APP_BASE_URL;
    static channels: Collection = collection;
    static _channels = new BehaviorSubject<Collection>(this.channels);
    static channel: Channel = channelEntity;
    static _channel = new BehaviorSubject<Channel>(this.channel);

    private static token: string = localStorage.getItem('API_TOKEN') ?? "";

    static getCollection = (t: any) => {
        http.get(this.baseUrl + 'channels', {headers: {Authorization: this.token}})
            .subscribe({
                next: (e: any) => {
                    this.channels = e.data["hydra:member"];
                    this._channels.next(this.channels);
                },
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"}))
            });
    };


    static post = (data: Partial<Channel>, t: any) => {
        http.post(this.baseUrl + `channels`, data, {headers: {Authorization: this.token}})
            .subscribe({
                next: (e: any) => {
                    this.channel = {...channelEntity, ...e.data};
                    this._channel.next(this.channel);
                },
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"})),
                complete: () => toast.success(t("chanel", {ns: "glossary"}) + t("success.created", {ns: "utils/notification"}))
            });
    };
    static get = (id: string | undefined, t: any) => {
        http.get(this.baseUrl + `channels/${id}`, {headers: {Authorization: this.token}})
            .subscribe({
                next: (e: any) => {
                    this.channel = {...channelEntity, ...e.data};
                    this._channel.next(this.channel);
                },
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"}))
            });
    };

    static put = (id: string | undefined, data: Partial<Channel>, t: any) => {
        http.put(this.baseUrl + `channels/${id}`, data, {headers: {Authorization: this.token}})
            .subscribe({
                next: (e: any) => {
                    this.channel = {...channelEntity, ...e.data};
                    this._channel.next(this.channel);
                },
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"})),
                complete: () => toast.success(t("channel", {ns: "glossary"}) + t("success.modified", {ns: "utils/notification"}))
            });
    };

    static delete = (id: string | undefined, t: any) => {
        http.delete(this.baseUrl + `channels/${id}`, {headers: {Authorization: this.token}})
            .subscribe({
                next: () => this.getCollection(t),
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"})),
                complete: () => toast.success(t("channel", {ns: "glossary"}) + t("success.deleted", {ns: "utils/notification"}))
            });
    };

    static incrementTarget(target: string, id: string, variableName: string[]) {
        // @ts-ignore
        this.channel = {...this.channel, [variableName[0]]: this.channel[variableName[0]] + 1, [variableName[1]]: id}
        this._channel.next(this.channel);
    }
    static decrementTarget(target: string, variableName: string[]) {
        // @ts-ignore
        this.channel = {...this.channel , [variableName[0]]: this.channel[variableName[0]] - 1, [variableName[1]]: false};
        this._channel.next(this.channel);
    }
}

export default channelService;
