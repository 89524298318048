import {BehaviorSubject} from "rxjs";
import {Category} from "../@types/category";
import http from "./config/http.service";
import {Collection} from "../@types/collection";
import categoryEntity from "../_emptyEntity/category";
import collection from "../_emptyEntity/Collection";
import {toast} from "react-toastify";

class categoryService {
    static baseUrl = process.env.REACT_APP_BASE_URL;
    static categories: Collection = collection;
    static _categories = new BehaviorSubject<Collection>(this.categories);
    static category: Category = categoryEntity;
    static _category = new BehaviorSubject<Category>(this.category);
    private static token: string = localStorage.getItem('API_TOKEN') ?? "";

    static getCollection = (t: any, takeNumber?: number) => {
        http.get(this.baseUrl + 'categories', {headers: {Authorization: this.token}, params:{take: takeNumber}})
            .subscribe({
                next: (e: any) => {
                    this.categories = e.data["hydra:member"];
                    this._categories.next(this.categories);
                },
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"})),
            });
    };
    static post = (data: Partial<Category>, t: any) => {
        http.post(this.baseUrl + `categories`, data, {headers: {Authorization: this.token}})
            .subscribe({
                next: (e: any) => {
                    this.category = {...categoryEntity, ...e.data};
                    this._category.next(this.category);
                    this.getCollection(t)
                },
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"})),
                complete: () => toast.success(t("category", {ns: "glossary"}) + t("success.created", {ns: "utils/notification"}))
            });
    };
    static get = (id: string | undefined, t: any) => {
        http.get(this.baseUrl + `categories/${id}`, {headers: {Authorization: this.token}})
            .subscribe({
                next: (e: any) => {
                    this.category = {...categoryEntity, ...e.data};
                    this._category.next(this.category);
                },
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"})),
            });
    };

    static put = (id: string | undefined, data: Partial<Category>, t: any) => {
        http.put(this.baseUrl + `categories/${id}`, data, {headers: {Authorization: this.token}})
            .subscribe({
                next: (e: any) => {
                    this.category = {...categoryEntity, ...e.data};
                    this._category.next(this.category);
                    this.getCollection(t)
                },
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"})),
                complete: () => toast.success(t("category", {ns: "glossary"}) + t("success.modified", {ns: "utils/notification"}))
            });
    };

    static delete = (id: string | undefined, t: any) => {
        http.delete(this.baseUrl + `categories/${id}`, {headers: {Authorization: this.token}})
            .subscribe({
                next: () => this.getCollection(t),
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"})),
                complete: () => toast.success(t("category", {ns: "glossary"}) + t("success.deleted", {ns: "utils/notification"}))
            });
    };
}

export default categoryService;