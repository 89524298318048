import React, {FC, useContext, useState} from "react";
import {useTheme} from "@mui/material/styles";
import AppBar from "../../components/layout/header/appBar/AppBar";
import Drawer from "../../components/layout/header/Drawer";
import {LayoutContext} from "../../layout/FullLayout";

const Header: FC = () => {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const {setWidth} = useContext(LayoutContext);
    const handleDrawerToggle = () => {
        setOpen(!open);
        setWidth((!open)?"270px": "64px")
    };
    return (
        <>
            <Drawer handleDrawerToggle={handleDrawerToggle} open={open}/>
            <AppBar handleDrawerToggle={handleDrawerToggle} open={open}/>
        </>
    );
}
export default Header;
