const channelEntity = {
    "@context": "",
    "@id": "",
    "@type": "",
    "id": "",
    "title": "",
    "description": "",
    "followNumber": 0,
    "isFollowed": false,
    "ratings": [],
    "ratingNumber":0,
    "averageRating":0,
    "person": {},
    "createdAt": "",
    "updatedAt": ""
};
export default channelEntity;