import {Box, styled, Theme} from "@mui/material";

export const OutlineBorderPaper = styled("fieldset")(({theme}:any) => ({
    border: "2.5px solid",
    borderColor: theme.palette.secondary.main,
    color: theme.palette.secondary.main,
    justifyItems: "center",
    marginLeft: "auto",
    marginRight: "auto",
    borderRadius: 10,
    padding: "10px",
    justifyContent: "center",
    backgroundColor:theme.palette.background.paper,
    '& > p': {

    }
}));