import {BehaviorSubject} from "rxjs";
import {Tag} from "../@types/tag";
import http from "./config/http.service";
import {Collection} from "../@types/collection";
import tagEntity from "../_emptyEntity/tag";
import collection from "../_emptyEntity/Collection";
import {toast} from "react-toastify";

class tagService {
    static baseUrl = process.env.REACT_APP_BASE_URL;
    static tags: Collection = collection;
    static _tags = new BehaviorSubject<Collection>(this.tags);
    static tag: Tag = tagEntity;
    static _tag = new BehaviorSubject<Tag>(this.tag);
    private static token: string = localStorage.getItem('API_TOKEN') ?? "";

    static getCollection = ( t: any, takeNumber?: number) => {
        http.get(this.baseUrl + 'tags', {headers: {Authorization: this.token}, params:{take: takeNumber}})
            .subscribe({
                next: (e: any) => {
                    this.tags = e.data["hydra:member"];
                    this._tags.next(this.tags);
                },
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"})),
            });
    };
    static getTargetCollection = (targetable: string, t: any, takeNumber?: number) => {
        http.get(this.baseUrl + targetable + '/tags', {headers: {Authorization: this.token}, params: {take: takeNumber}})
            .subscribe({
                next: (e: any) => {
                    this.tags = e.data["hydra:member"];
                    this._tags.next(this.tags);
                },
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"})),
            });
    };
    static post = (data: Partial<Tag>, t: any) => {
        http.post(this.baseUrl + `tags`, data, {headers: {Authorization: this.token}})
            .subscribe({
                next: (e: any) => {
                    this.tag = {...tagEntity, ...e.data};
                    this._tag.next(this.tag);
                    this.getCollection(t)
                },
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"})),
                complete: () => toast.success(t("tag", {ns: "glossary"}) + t("success.created", {ns: "utils/notification"}))
            });
    };
    static get = (id: string | undefined, t: any) => {
        http.get(this.baseUrl + `tags/${id}`, {headers: {Authorization: this.token}})
            .subscribe({
                next: (e: any) => {
                    this.tag = {...tagEntity, ...e.data};
                    this._tag.next(this.tag);
                },
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"})),
            });
    };

    static put = (id: string | undefined, data: Partial<Tag>, t: any) => {
        http.put(this.baseUrl + `tags/${id}`, data, {headers: {Authorization: this.token}})
            .subscribe({
                next: (e: any) => {
                    this.tag = {...tagEntity, ...e.data};
                    this._tag.next(this.tag);
                },
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"})),
                complete: () => toast.success(t("tag", {ns: "glossary"}) + t("success.modified", {ns: "utils/notification"}))
            });
    };

    static delete = (id: string | undefined, t: any) => {
        http.delete(this.baseUrl + `tags/${id}`, {headers: {Authorization: this.token}})
            .subscribe({
                next: () => this.getCollection(t),
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"})),
                complete: () => toast.success(t("tag", {ns: "glossary"}) + t("success.deleted", {ns: "utils/notification"}))
            });
    };
}

export default tagService;