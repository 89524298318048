import React, {FC, useEffect, useState, useTransition} from 'react';
import Page from "../../../components/layout/Page";
import {Box, Container, Grid, IconButton, Paper, Stack, TableContainer, Tooltip, Typography} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import MyTable from "../../../components/_admin/MyTable";
import {StyledTableCell, StyledTableRow} from "../../../_styles/TableStyle";
import Loader from "../../../components/layout/Loader";
import {useTranslation} from "react-i18next";
import {Tag} from "../../../@types/tag";
import DeleteModal from "../../../components/common/DeleteModal";
import TagService from "../../../services/tag.service";
import FormAddTag from "../../../components/Tag/Modal/FormAddTag";
import CategoryService from "../../../services/category.service";
import {Category} from "../../../@types/category";
import FormAddCategory from "../../../components/Category/Modal/FormAddCategory";
import FormEditCategory from "../../../components/Category/Modal/FormEditCategory";
import EditIcon from "@mui/icons-material/Edit";

const CategoryManagement:FC <{}> = () => {
    const {t} = useTranslation();
    const [categories, setCategories] = useState<Category[]>([]);
    const [loading, startTransition] = useTransition();
    const [openAdd, setOpenAdd] = useState(false);

    useEffect(() => {
        startTransition(() => CategoryService.getCollection(t))
        const categories = CategoryService._categories.subscribe((e: any) => setCategories(e));
        return () => {
            categories.unsubscribe();
        };
    }, []);

    const hearderList = ['Label', 'Description', 'Modifier','Supprimer']

    return (
        <Page title="Gestion des Catégories">
        <Grid container spacing={2}>
                <Grid item md={12}>
                    <Container>
                        <TableContainer component={Paper} elevation={20}>
                            <Stack sx={{p: 1}} direction="row" justifyContent="space-between" alignItems="center">
                                <Typography sx={{p: 1}} variant="h3">Catégories</Typography>
                                <IconButton onClick={() => setOpenAdd(true)}><AddIcon/></IconButton>
                            </Stack>
                            {!loading ?
                                <MyTable headerList={hearderList}>
                                    {categories.length > 0 &&
                                        categories.map((category, index) =>
                                            <StyledTableRow key={index}>
                                                <StyledTableCell><Typography>{t(category.label, {ns: "common/tag"})}</Typography></StyledTableCell>
                                                <StyledTableCell><Typography>{t(`${category.description}`, {ns: "common/category"})}</Typography></StyledTableCell>
                                                <StyledTableCell>
                                                    <FormEditCategory category={category}/>
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <DeleteModal id={category.id} entityName="category"/>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )}
                                </MyTable>
                                :
                                <Loader/>
                            }
                            <FormAddCategory openAction={openAdd} setOpenAction={setOpenAdd}/>
                        </TableContainer>
                    </Container>
                </Grid>
            </Grid>
        </Page>
    );
};

export default CategoryManagement;
