import React, {startTransition, useContext, useEffect, useState, useTransition} from 'react';
import tutorialService from "../../../services/tutorial.service";
import Page from "../../../components/layout/Page";
import {Grid, Stack, Typography} from "@mui/material";
import TutorialCard from "../../../components/common/card/TutorialCard";
import Loader from "../../../components/layout/Loader";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {PersonContext} from "../../../App";

const WorkroomTutorialCollection = () => {
    const {t} = useTranslation();
    const [tutorials, setTutorials] = useState<any>([]);
    const [loading, startTransition] = useTransition();
    const [totalTutorial, setTotalTutorial] = useState(0);
    const {currentPerson} = useContext(PersonContext);
    const [order, setOrder] = useState(`order[createdAt]=asc`);

    useEffect(() => {
        startTransition(() => tutorialService.getCollection(t, undefined, {"person.id": currentPerson.id}));
        const tutorials = tutorialService._tutorials.subscribe((e: any) => setTutorials(e));
        return () => {
            tutorials.unsubscribe();
        };
    }, []);

    return (
        <Page title={t("tutorial", {ns: "glossary"}) + 's'}>
            <Grid container justifyContent="space-evenly">
                {!loading ?
                    tutorials.length > 0 ?
                        tutorials?.map((tutorial: any, index: any) => (
                            <Grid key={index} item md={3} sx={{position: 'relative'}}>
                                <TutorialCard tutorial={tutorial} page="workroom"/>
                            </Grid>
                        )) :
                        <Stack alignItems="center" justifyContent="center">
                            <Typography variant="h3">Il n'y a pas de tutoriels</Typography>
                        </Stack>
                    : <Loader/>
                }
            </Grid>
        </Page>
    );
};

export default WorkroomTutorialCollection;
