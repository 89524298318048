import React, {FC, useContext, useEffect, useState, useTransition} from "react";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import Page from "../../../components/layout/Page";
import {Button, Grid, Stack, Typography} from "@mui/material";
import materialTargetService from "../../../services/materialTarget.service";
import {PersonContext} from "../../../App";
import AddMaterial from "../../../components/material/AddMaterial";
import materialService from "../../../services/material.service";
import MaterialTargetCard from "../../../components/common/targetable/materialTarget/MaterialTargetCard";

const MyMaterialCollection: FC<{ pageType?: string; }> = ({pageType}) => {
    const navigate = useNavigate();
    const location: any = useLocation();
    const {t} = useTranslation();
    const {currentPerson} = useContext(PersonContext);
    const [targetMaterials, setTargetMaterials] = useState<any>([]);
    const [loading, startTransition] = useTransition();
    const [order, setOrder] = useState(`order[createdAt]=asc`);

    useEffect(() => {
        startTransition(() => {
            materialTargetService.getCollection(currentPerson.id, 'people', t)
        })
        const materialTarget = materialTargetService._targetMaterials.subscribe((e: any) => setTargetMaterials(e));
        return () => {
            materialTarget.unsubscribe();
        };
    }, [currentPerson.id, t]);
    console.log('materials', targetMaterials)
    // const api = `/materials?${`page=${params.page}`}&itemsPerPage=${12}
    // &${order}${params.term ? `&term=${params.term}` : ""}`;
    return (
        <Page title={t("default_menu.material", {ns: "header/appBar"}) + 's'}>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h1">{t("default_menu.materials", {ns: "header/sideBar"})}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="row" justifyContent="center" alignItems="center">
                        <AddMaterial targetable={"people"}/>
                        <Typography> ou </Typography>
                        <Button onClick={() => navigate('/materials')}>Voir la liste des matériaux</Button>
                    </Stack>
                </Grid>
                {targetMaterials.length > 0 &&
                    targetMaterials?.map((targetMaterial: any, index: any) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={index}>
                            <MaterialTargetCard
                                materialTarget={targetMaterial}
                                targetable={"people"}
                                targetableId={currentPerson.id}
                            />
                        </Grid>
                    ))}
            </Grid>
            {/*  {totalMaterial > materials?.length ?
                <Box display="center" justifyContent="center" sx={{mt: 2}}>
                    <Pagination
                        count={Math.ceil(totalMaterial / 10)}
                        page={httpParams.page}
                        onChange={(event, value) => handlePage(value)}
                        shape="rounded"
                    />
                </Box>
                :
                <Box sx={{mt: 6}}>
                    <Typography variant="body1" textAlign="center">
                        {t("LIST.NO_RESULT")}
                    </Typography>
                </Box>
            }*/}
        </Page>
    );
};

export default MyMaterialCollection;