import DeleteIcon from "@mui/icons-material/Delete";
import {Avatar, Chip, IconButton, Input, ListItem, ListItemAvatar, ListItemText, Modal, Stack} from "@mui/material";
import React, {FC, useState} from "react";
import {useTranslation} from "react-i18next";
import List from "@mui/material/List";
import MediaObjectService from "../../../../../services/MediaObject.service";
import {MediaObject} from "../../../../../@types/MediaObject";
import {CustomButton} from "../../../../../_styles/ButtonStyle";
import {ModalBox} from "../../../../../_styles/ModalStyle";
import FormAddOneMediaObject from "../../../../mediaObject/FormAddOneMediaObject";
import materialService from "../../../../../services/material.service";
import tutorialService from "../../../../../services/tutorial.service";
import makeService from "../../../../../services/make.service";
import SubStepService from "../../../../../services/subStep.service";
import FlipCameraIosIcon from '@mui/icons-material/FlipCameraIos';
import SaveIcon from "@mui/icons-material/Save";
import StepService from "../../../../../services/step.service";

const TargetablePictureManagementModal: FC<{ target: any, mediaObject: string, updateTargetId?: string }>
    = ({target, mediaObject, updateTargetId}) => {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const [edit, setEdit] = useState(false);
    const [name, setName] = useState<string>();
    const handleClose = () => {
        setOpen(false);
    }

    const submitName = (id: string) => {
        MediaObjectService.put(id, {name: name}, mediaObject, t)
        update(target["@type"])
        setEdit(false)
    }
    const deletePicture = (id: string) =>
        MediaObjectService.delete(mediaObject, id, t)
    const editCover = (id: string) => {
        console.log(target.coverPicture.id)
        let coverPicture = (target.coverPicture.id === "" || target.coverPicture.id !== id) ? id : null
        switch (target["@type"]) {
            case "Tutorial":
                tutorialService.put(target.id, {coverPicture: coverPicture}, t)
                break
            case "Material":
                materialService.put(target.id, {coverPicture: coverPicture}, t)
                break
            case "Make":
                makeService.put(target.id, {coverPicture: coverPicture}, t)
                break
            case "SubStep":
                if (updateTargetId)
                    SubStepService.put(target.id, {coverPicture: coverPicture}, updateTargetId, t)
                break
        }
    }

    const update = (targetable: string) => {
        switch (targetable) {
            case "Tutorial":
                tutorialService.get(target.id, t)
                break
            case "Material":
                materialService.get(target.id, t)
                break
            case "Make":
                makeService.get(target.id, t)
                break
            case "SubStep":
                if (updateTargetId)
                    StepService.getCollection(updateTargetId, t)
                break
        }
    }

    return (
        <>
            <CustomButton onClick={() => setOpen(true)} variant="contained">Ajouter une image</CustomButton>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby={`pictures-management-${target.id}`}
                aria-describedby="pictures-management-modal"
            >
                <ModalBox>
                    <Stack direction='column' justifyContent='center' spacing={2}>
                        <FormAddOneMediaObject target={target}
                                               differentUpdateTarget={updateTargetId}
                                               mediaObject={mediaObject}
                                               type={(target.pictures.length === 0) ? "cover" : ""}/>
                        <List dense={true}>
                            {target.pictures?.map((picture: MediaObject, index: number) => (
                                edit ?
                                    <>
                                        <Input name="name" type="text" defaultValue={picture.name} value={name}
                                               onChange={(e) => setName(e.target.value)}/>
                                        <IconButton onClick={() => submitName(picture.id)}><SaveIcon/></IconButton>
                                    </>
                                    :
                                    <ListItem
                                        key={index}
                                        secondaryAction={
                                            <>
                                                {target?.coverPicture?.id === picture?.id &&
                                                    <Chip label="cover"/>
                                                }
                                                <IconButton edge="end" aria-label="edit-cover"
                                                            onClick={() => editCover(picture["@id"])}>
                                                    <FlipCameraIosIcon/>
                                                </IconButton>
                                                <IconButton edge="end" aria-label="delete"
                                                            onClick={() => deletePicture(picture.id)}>
                                                    <DeleteIcon/>
                                                </IconButton>
                                            </>
                                        }
                                    >
                                        <ListItemAvatar>
                                            <Avatar variant="square" src={picture.contentUrl} alt={picture.name}/>
                                        </ListItemAvatar>
                                        <ListItemText primary={picture.name} onClick={() => setEdit(true)}/>
                                    </ListItem>

                            ))}
                        </List>
                    </Stack>
                </ModalBox>
            </Modal>
        </>
    );
};

export default TargetablePictureManagementModal;
