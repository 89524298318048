import React, {FC, useEffect, useState, useTransition} from "react";
import {Box, Button, IconButton, Input, MenuItem, Modal, Stack, Typography} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {Resolver, SubmitHandler, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import useFormHook from '../../../../../../hooks/FormHook'
import TextInput from "../../../../../common/formInputs/TextInput";
import {CustomButton} from "../../../../../../_styles/ButtonStyle";
import {ModalBox} from "../../../../../../_styles/ModalStyle";
import {SubStep, SubStepJson} from "../../../../../../@types/subStep";
import subStepService from "../../../../../../services/subStep.service";
import ProfilePictureButton from "../../../../../common/deprecated/ProfilePictureButton";
import SelectInput from "../../../../../common/formInputs/SelectInput";
import {Step} from "../../../../../../@types/step";

const FormAddSubStep: FC<{ step: Step, tutorialId: string ,openAction: boolean, setOpenAction: any }>
    = ({step, tutorialId, openAction, setOpenAction}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [loading, startTransition] = useTransition();

    const resolver: Resolver<SubStepJson> = async (values) => {
        let errors = {};
        // Validate all fields on submit event
        errors = Object.entries(values).reduce(
            (acc, [name, value]) => (value !== "duration") ? useFormHook.hasError(name, value, acc, t) : null, {});
        console.log(errors)
        return {values, errors};
    };
    const {register, handleSubmit, reset, watch, setError, getValues, formState: {errors}} = useForm<SubStepJson>({
        resolver,
        mode: "onBlur"
    });
    const onSubmit: SubmitHandler<SubStepJson> = data => {
        console.log('data', data, errors);
        if (Object.keys(errors).length === 0) {
            startTransition(() => {
                subStepService.post(data, tutorialId, t)
                reset()
            })
        }
        handleClose()
    };

    const [open, setOpen] = useState(openAction);
    const handleClose = () => {
        setOpen(false);
        setOpenAction(false)
    }

    useEffect(() => {
        setOpen(openAction)
    }, [openAction]);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <ModalBox>
                <Stack direction="row" justifyContent="flex-end">
                    <IconButton onClick={handleClose} style={{fontSize: "normal"}}>
                        <CloseIcon fontSize="large"/>
                    </IconButton>
                </Stack>
                <Typography id="modal-modal-title" variant="h3" component="h2" style={{textAlign: "center"}}>
                    {t("add", {ns: "glossary"}) + ' '
                        + t("a_f", {ns: "glossary"}) + ' '
                        + t("sub_step", {ns: "glossary"}).toLowerCase()}
                </Typography>
                <Box
                    component="form"
                    sx={{'& .MuiTextField-root': {m: 1, width: '25ch'}}}
                    noValidate
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Stack direction='column' spacing={2} sx={{width: '100%'}}>
                        <Input sx={{position: 'unset'}} type="hidden" {...register('step')} required
                               value={step['@id']}/>
                        <TextInput name='title' register={register} required error={errors.title}
                                   helperText={(errors?.title?.message)?t(errors.title.message,{ns:'common/form'}):''} label={t('label.common.title', {ns:'common/form'})}/>
                        <SelectInput name='direction' value={watch("direction") ?? ''} label={t('label.subStep.direction', {ns: 'common/form'})} error={errors.direction} register={register} helperText={(errors?.direction?.message) ? t(errors.direction.message, {ns: 'common/form'}) : ''}>
                            <MenuItem value='left'>{t("left",{ns: 'common/form'})}</MenuItem>
                            <MenuItem value='right'>{t("right", {ns: 'common/form'})}</MenuItem>
                            <MenuItem value='up'>{t("up", {ns: 'common/form'})}</MenuItem>
                            <MenuItem value='down'>{t("down", {ns: 'common/form'})}</MenuItem>
                        </SelectInput>
                        <TextInput name='content' register={register} required error={errors.content}
                                   helperText={(errors?.content?.message) ? t(errors.content.message, {ns: 'common/form'}) : ''} label={t('label.common.content', {ns: 'common/form'})}/>
                        <SelectInput name='number' value={watch("number") ?? ''} label={t('label.common.number', {ns: 'common/form'})} required error={errors.number} register={register}
                                     helperText={(errors?.number?.message) ? t(errors.number.message, {ns: 'common/form'}) : ''}>
                            <MenuItem value={'1'}>1</MenuItem>
                            <MenuItem value={'2'}>2</MenuItem>
                            <MenuItem value={'3'}>3</MenuItem>
                            <MenuItem value={'4'}>4</MenuItem>
                            <MenuItem value={'5'}>5</MenuItem>
                            <MenuItem value={'6'}>6</MenuItem>
                        </SelectInput>
                    </Stack>
                    <div style={{marginTop: "35px"}}>
                        <Button
                            variant="contained"
                            onClick={handleClose}
                            style={{backgroundColor: "crimson"}}
                        >
                            {t("cancel", {ns: 'glossary'})}
                        </Button>
                        <CustomButton disabled={loading} variant="contained" type="submit">
                            {t("add", {ns: 'glossary'})}
                        </CustomButton>
                    </div>
                </Box>
            </ModalBox>
        </Modal>
    )
}

export default FormAddSubStep