import React, {FC, useContext, useEffect, useState, useTransition} from 'react';
import AutoCompleteInput from "../common/formInputs/AutoCompleteInput";
import {useTranslation} from "react-i18next";
import {PersonContext} from "../../App";
import tagService from "../../services/tag.service";
import tagTargetService from "../../services/tagTarget.service";
import {TagTarget} from "../../@types/tagTarget";

const AddTag: FC<{ tagTarget: TagTarget }> = ({tagTarget}) => {
    const {t} = useTranslation();
    const [values, setValues] = useState<any[] | null>([]);
    const {currentPerson} = useContext(PersonContext);
    const [loading, startTransition] = useTransition();
    const [tags, setTags] = useState([]);

    useEffect(() => {
        startTransition(() => tagService.getCollection(t))
        const tags = tagService._tags.subscribe((e: any) => setTags(e));
        return () => {
            tags.unsubscribe();
        };
    }, []);

    const addTagTarget = () => {
        let data: Partial<TagTarget> = {tags:[...tagTarget.tags.map((tag, index) => tag['@id'])]}
        console.log('tag value', values)
        values?.map((value, index) => data.tags?.push(value['@id']))
        console.log('tagTarget', data)
        tagTargetService.put(tagTarget.id, data, t)
        setValues([])
    }

    return (
        <AutoCompleteInput collection={tags} setValues={setValues} addAction={addTagTarget} placeholder="tags"/>
    )
};

export default AddTag;
