import {FC, useTransition} from "react";
import {Resolver, SubmitHandler, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {Box, Stack} from "@mui/material";
import {MediaObject} from "../../@types/MediaObject";
import useFormHook from "../../hooks/FormHook";
import mediaObjectService from "../../services/MediaObject.service";
import TextInput from "../common/formInputs/TextInput";
import {CustomButton} from "../../_styles/ButtonStyle";
import {FileInput} from "../../_styles/FormInputStyle";

const FormAddOneMediaObject: FC<{ target: any, mediaObject: string, type: string, differentUpdateTarget?: string }>
    = ({target, mediaObject, type, differentUpdateTarget}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const config = {"Content-Type": 'multipart/form-data'}

    const [loading, startTransition] = useTransition();

    const resolver: Resolver<MediaObject> = async (values) => {
        let errors = {};
        // Validate all fields on submit event
        errors = Object.entries(values).reduce(
            (acc, [name, value]) => (name !== "type") ? useFormHook.hasError(name, value, acc, t) : {}, {});
        console.log('errors', errors)
        return {values, errors};
    };
    const {register, handleSubmit, watch, setError, formState: {errors}} = useForm<MediaObject>({
        resolver,
        mode: "onBlur"
    });
    const onSubmit: SubmitHandler<MediaObject> = data => {
        const form = new FormData()
        form.append('file', data.file[0]);
        form.append('targetIri', target["@id"]);
        form.append('name', data.name);
        form.append('type', type);


        console.log('file', form.entries());
        console.log('data', form);
        if (Object.keys(errors).length === 0) {
            startTransition(() => {
                mediaObjectService.post(form, mediaObject, target["@type"],differentUpdateTarget??target.id, t, config)
            })
        }
    };

    return (
        <Box
            component="form"
            sx={{'& .MuiTextField-root': {m: 1, width: '25ch'}}}
            noValidate
            onSubmit={handleSubmit(onSubmit)}
        >
            <Stack direction='column' sx={{width: '100%'}}>
                <label>{t("label.common.choose_file", {ns: "common/form"})}
                    <FileInput {...register('file', {required: false})} type="file"/>
                </label>
                <TextInput name='name' register={register} required
                           helperText={errors?.name?.message} label='Name'/>
            </Stack>
            <CustomButton disabled={loading} variant="contained" type="submit">
                {t("add", {ns: 'glossary'})}
            </CustomButton>
        </Box>
    )
}
export default FormAddOneMediaObject