import materialEntity from "./material";

const materialTargetEntity = {
    "@context": "",
    "@id": "",
    "@type": "",
    "id": "",
    "material": materialEntity,
    "quantity": 0,
    "link": "",
    "createdAt": "",
    "updatedAt": ""
};
export default materialTargetEntity;