import React, {FC, ReactNode} from 'react';
import {Table, TableBody, TableHead, TableRow, Typography} from "@mui/material";
import {StyledTableCell} from "../../_styles/TableStyle";

const MyTable: FC<{ headerList: string[], children: ReactNode }>
    = ({headerList, children}) => {
    return (
        <Table sx={{minWidth: 500}} size="small" aria-label="customized table">
            <TableHead>
                <TableRow>
                    {headerList.map((item, index) =>
                        item === 'Titre' ?
                        <StyledTableCell key={index} colSpan={3}><Typography variant={'h5'}>{item}</Typography></StyledTableCell>
                        :<StyledTableCell key={index}><Typography variant={'h5'}>{item}</Typography></StyledTableCell>
                    )}
                </TableRow>
            </TableHead>
            <TableBody>
                {children}
            </TableBody>
        </Table>
    );
};

export default MyTable
