import {FC, useEffect, useState} from 'react';
import {Box, Chip, ImageList, ImageListItem, ImageListItemBar, Stack, Typography} from "@mui/material";
import YoutubeEmbed from "../../../../pages/body/tutorial/YoutubeEmbed";
import {PlayCircle} from "@mui/icons-material";
import {useTheme} from "@mui/material/styles";
import {MediaObject} from "../../../../@types/MediaObject";

const PictureGallery: FC<{ coverPicture: MediaObject, pictures: MediaObject[], video?: string }>
    = ({coverPicture, pictures, video}) => {

    const [bigPicture, setBigPicture] = useState<MediaObject>();
    const theme = useTheme()

    useEffect(() => {
        setBigPicture(pictures[0])
    }, [pictures]);
    const handleClick = (picture: MediaObject) => {
        setBigPicture(picture)
    }

    return (
        <Box>
            {bigPicture && pictures.length > 0 ?
                <>
                    {video ?
                        <YoutubeEmbed
                            embedId="gVhA1qnnkbY?clip=Ugkx0WfbZFpLlYy3X76_VZFm5nENM3-qAp-H&amp;clipt=EJ6DIBi58SA"
                            height={250}/>
                        :
                        bigPicture.contentUrl ?
                            <Stack alignItems="center" bgcolor={"#000"}>
                                <img height={300} style={{border: "solid black 1px"}}
                                     src={bigPicture.contentUrl}
                                     alt={bigPicture.name}/>
                            </Stack>
                            :
                            <Typography>Pas de photo de couverture défini</Typography>

                    }
                    <ImageList sx={{width: "100%"}} cols={3} rowHeight={"auto"}>
                        {pictures.map((picture, index) => (
                            video ?
                                <ImageListItem key={index} style={{paddingTop: 10}}>
                                    <Box onClick={() => handleClick(picture)}>
                                        <Box bgcolor="lightgrey" pt="35px" textAlign="center"
                                             alignItems="center" color={theme.palette.primary.main}>
                                            <PlayCircle fontSize="large"/>
                                        </Box>
                                    </Box>
                                </ImageListItem>
                                :
                                <ImageListItem key={index} style={{paddingTop: 10}}>
                                    <img
                                        src={picture.contentUrl}
                                        style={{border: "solid black 1px"}}
                                        alt={picture.name}
                                        loading="lazy"
                                        onClick={() => handleClick(picture)}
                                    />
                                    <ImageListItemBar
                                        sx={{background: "none",}}
                                        title={(coverPicture?.id === picture.id) ?
                                            <Chip label="cover" style={{height: "none"}} color={"primary"}/> : null}
                                        position="top"
                                    />
                                </ImageListItem>

                        ))}
                    </ImageList>
                </> :
                <Typography variant={"h4"}>Il n'y a pas encore d'images</Typography>
            }
        </Box>
    );
};

export default PictureGallery;
