import React, {FC} from 'react';
import {Avatar, Box, Chip, Grid, Paper, Stack, Typography} from "@mui/material";
import {OutlineBorderPaper} from "../../../../_styles/PaperStyle";
import FormEditTutorial from "../../../tutorial/modal/FormEditTutorial";
import {Delete} from "@mui/icons-material";
import {Tutorial} from "../../../../@types/tutorial";
import {useTranslation} from "react-i18next";
import tutorialService from "../../../../services/tutorial.service";
import PictureGallery from "../../../common/targetable/picture/PictureGallery";
import TargetablePictureManagementModal from "../../../common/targetable/picture/modal/TargetablePictureManagementModal";
import AddCategory from "../../../Category/AddCategory";

const TutorialSection: FC<{ tutorial: Tutorial }> = ({tutorial}) => {
    const {t} = useTranslation();

    return (
        <Grid item xs={12}>
            <OutlineBorderPaper>
                <Typography variant="h2" component="legend">
                    {t("edit", {ns: "glossary"}) + ' '
                        + t("un", {ns: "glossary"}) + ' '
                        + t("tutorial", {ns: "glossary"}).toLowerCase()}
                </Typography>
                <Paper>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <FormEditTutorial tutorial={tutorial}/>
                        </Grid>
                        <Grid item xs={12} md={6} p={1}>
                            <Stack spacing={2}>
                                <PictureGallery coverPicture={tutorial.coverPicture} pictures={tutorial.pictures}/>
                                <TargetablePictureManagementModal target={tutorial} mediaObject="tutorial_media_objects"/>
                                <Box>
                                    {tutorial.title}
                                    <AddCategory tutorial={tutorial}/>
                                    <Typography variant="h4"> Liste des categories</Typography>
                                    {tutorial.categories.map((category, index) => (
                                        <Chip
                                            key={index}
                                            avatar={<Avatar alt="Natacha"
                                                            src="/static/images/avatar/1.jpg"/>}
                                            label={category.label}
                                            onDelete={() => tutorialService.deleteCategory(index,t)}
                                            deleteIcon={<Delete/>}
                                        />
                                    ))}
                                </Box>
                            </Stack>
                        </Grid>
                    </Grid>
                </Paper>
            </OutlineBorderPaper>
        </Grid>
    );
};

export default TutorialSection;
