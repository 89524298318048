import React, {FC} from 'react';
import {Box, Button, CardActionArea, CardActions, CardMedia, Link, Stack, Typography} from "@mui/material";
import {MyCard, MyCardContent} from "../../../_styles/CardStyle";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {Tutorial} from "../../../@types/tutorial";
import MyRating from "../targetable/MyRating";
import Duration from "../targetable/Duration";
import Cost from "../targetable/Cost";
import PEGI from "../targetable/PEGI";
import PreviewTutorial from "../../tutorial/modal/PreviewTutorial";
import FavoriteIcon from "@mui/icons-material/Favorite";
import {formatDistanceToNowStrict, parseISO} from "date-fns";
import {fr} from "date-fns/locale";
import VisibilityIcon from '@mui/icons-material/Visibility';

const TutorialCard: FC<{ tutorial: Tutorial, page?: string }> = ({tutorial, page}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [over, setOver] = React.useState(false);

    return (
        <>
            <MyCard difficulty={tutorial.difficulty} onMouseOver={() => setOver(true)}
                    onMouseLeave={() => setOver(false)}>
                <CardActionArea onClick={() => {
                    switch (page) {
                        case "workroom":
                            navigate(`/workroom-factory/${tutorial.id}`)
                            break;
                        default:
                            setOpen(true)
                            break;
                    }
                }}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center"
                           sx={{position: 'absolute', width: 'inherit', color: "white", padding: '5px 15px 0'}}>
                        <Box sx={{
                            backgroundColor: "rgba(157, 88, 222, 0.5)",
                            padding: 0.4,
                            borderRadius: 0.6
                        }}>
                            <Typography fontWeight="bold">By {tutorial.pseudo}</Typography>
                        </Box>
                        <MyRating targetable={tutorial}/>
                    </Stack>
                    <CardMedia
                        component="img"
                        height="194"
                        image={tutorial?.coverPicture?.contentUrl}
                        alt={tutorial?.coverPicture?.name}
                    >
                    </CardMedia>
                    <MyCardContent>
                        <Stack spacing={1} justifyContent="space-between">
                            <Stack direction="row" justifyContent="space-between">
                                <Stack spacing={0}>
                                    <Typography variant="h3">{tutorial.title}</Typography>
                                    <Typography variant="h4">By {tutorial.pseudo}</Typography>
                                </Stack>
                                <Typography sx={{marginTop: 1}}>
                                    {t("about_time", {ns: "glossary"}) +
                                        formatDistanceToNowStrict(new Date(parseISO(tutorial.createdAt)), {locale: fr})}
                                </Typography>
                            </Stack>
                                    <Stack direction="row" spacing={1} justifyContent="space-around" alignItems="center"
                                           flexWrap="wrap">
                                        {tutorial.tagTarget.tags?.map((tag, index) =>
                                            <Link key={index}>#{tag.label}</Link>
                                        )}
                                    </Stack>

                        </Stack>
                    </MyCardContent>
                </CardActionArea>
                {!page &&
                    <Stack sx={{padding: 1}}>
                        <Button sx={{height: 'max-content', marginTop: 1}} variant="contained"
                                onClick={() => navigate(`/tutorials/${tutorial.id}`)}>{t('begin', {ns: 'glossary'})}</Button>
                    </Stack>
                }
                <CardActions sx={{justifyContent: "space-evenly"}}>
                    <Stack direction="row" spacing={2} alignItems="center">
                        <Typography variant="h4">{tutorial?.view}</Typography><VisibilityIcon/>
                    </Stack>
                    <Stack direction="row" spacing={2} alignItems="center">
                        <Typography variant="h4">{tutorial?.favoriteNumber}</Typography><FavoriteIcon/>
                    </Stack>
                </CardActions>
            </MyCard>
            <PreviewTutorial tutorial={tutorial} openAction={open} setOpenAction={setOpen}/>
        </>
    );
};

export default TutorialCard
