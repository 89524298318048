import React, {FC} from 'react';
import {OutlineBorderPaper} from "../../../../_styles/PaperStyle";
import {Avatar, Chip, Grid, Paper, Typography} from "@mui/material";
import FormAddOneMediaObject from "../../../mediaObject/FormAddOneMediaObject";
import {Delete} from "@mui/icons-material";
import {Tutorial} from "../../../../@types/tutorial";

const FileSection: FC<{ tutorial: Tutorial,files: any[] }>
    = ({tutorial,files}) => {

    const handleClick = () => {

    }

    const handleDelete = () => {

    }

    return (
        <Grid item xs={12}>
            <OutlineBorderPaper>
                <Typography variant="h2" component="legend">Gestion des fichiers</Typography>
                <Paper>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <FormAddOneMediaObject target={tutorial} mediaObject="tutorial_media_objects"
                                                   type="img"/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h4"> Liste des fichiers</Typography>
                            {files.map((file, index) => (
                                <Chip
                                    key={index}
                                    avatar={<Avatar alt="Natacha" src="/static/images/avatar/1.jpg"/>}
                                    label={file.name}
                                    onClick={handleClick}
                                    onDelete={handleDelete}
                                    deleteIcon={<Delete/>}
                                />
                            ))}
                        </Grid>
                    </Grid>
                    {/* <FormAddManyMediaObject targetable="tutorial" mediaObject="tutorial_media_object"/>*/}
                </Paper>
            </OutlineBorderPaper>
        </Grid>
    );
};

export default FileSection;
