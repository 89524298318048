import React, {FC, useEffect, useState, useTransition} from 'react';
import {Box, Divider, Fab, Grid, Icon, Paper, Stack, Typography} from "@mui/material";
import {OutlineBorderPaper} from "../../../../_styles/PaperStyle";
import FormEditStep from "../step/FormEditStep";
import {AntTab, AntTabs} from "../../../../_styles/TabsStyle";
import AddIcon from "@mui/icons-material/Add";
import {TabPanel} from "../../../common/tabs/TabPanel";
import FormEditSubStep from "../step/subStep/FormEditSubStep";
import FormDirectionConfiguration from "../step/subStep/FormDirectionConfiguration";
import PictureGallery from "../../../common/targetable/picture/PictureGallery";
import {CustomButton} from "../../../../_styles/ButtonStyle";
import FormAddStep from "../step/modal/FormAddStep";
import {Tutorial} from "../../../../@types/tutorial";
import StepService from "../../../../services/step.service";
import {useTranslation} from "react-i18next";
import Button from "@mui/material/Button";
import {useTheme} from "@mui/material/styles";
import {Step} from "../../../../@types/step";
import FormAddSubStep from "../step/subStep/modal/FormAddSubStep";
import MaterialSkeleton from "../../../layout/skeleton/MaterialSkeleton";
import SubStepSection from "../step/SubStepSection";

const StepSection: FC<{ tutorial: Tutorial }> = ({tutorial}) => {
    const theme = useTheme()
    const {t} = useTranslation();
    const [openAddStep, setOpenAddStep] = useState(false);
    const [steps, setSteps] = useState<Step[]>([]);
    const [loading, startTransition] = useTransition();

    useEffect(() => {
        startTransition(() => {
            StepService.reset()
            StepService.getCollection(tutorial.id, t)
        })
        const steps = StepService._steps.subscribe((e: any) => setSteps(e));

        return () => {
            steps.unsubscribe();
        };
    }, []);

    return (
        <>
            {!loading ? steps.length === 0 ?
                    <Grid item xs={12}>
                        <OutlineBorderPaper>
                            <Typography variant="h2"
                                        component="legend">{`${t('the_p', {ns: 'glossary'})} ${t('step', {ns: 'glossary'}).toLowerCase()}s`}</Typography>
                            <Paper>
                                <Box width="100%" justifyContent="center" mb={5} display="flex">
                                    <Button onClick={() => setOpenAddStep(true)} sx={{
                                        width: "90%",
                                        height: "90%",
                                        backgroundColor: theme.palette.grey[500],
                                        border: "5px dashed",
                                        borderColor: theme.palette.grey[400],
                                        borderRadius: "15px"
                                    }}>
                                        <Typography textAlign="center" variant='h1'
                                                    component="p">{`${t('add', {ns: 'glossary'})} ${t('a_f', {ns: 'glossary'}).toLowerCase()} ${t('step', {ns: 'glossary'}).toLowerCase()}`}</Typography>
                                    </Button>
                                    <FormAddStep tutorial={tutorial} openAction={openAddStep}
                                                 setOpenAction={setOpenAddStep}/>
                                </Box>
                            </Paper>
                        </OutlineBorderPaper>
                    </Grid>
                    :
                    steps.length > 0 && steps?.map((step, index) => (
                        <Grid key={index} item xs={12}>
                            <OutlineBorderPaper>
                                <Typography variant="h2" component="legend">{`Slide ${step.label}`}</Typography>
                                <Paper>
                                    <FormEditStep step={step} tutorialId={tutorial.id}/>
                                    <SubStepSection step={step} tutorialId={tutorial.id}/>
                                </Paper>
                            </OutlineBorderPaper>
                        </Grid>
                    ))
                :
                <MaterialSkeleton/>
            }
            <Fab sx={{
                position: 'absolute',
                bottom: '16px',
                right: '16px'
            }}
                 onClick={() => setOpenAddStep(true)}
                 color="primary">
                <AddIcon/>
            </Fab>
            <FormAddStep tutorial={tutorial} openAction={openAddStep} setOpenAction={setOpenAddStep}/>
        </>
    );
};

export default StepSection;
