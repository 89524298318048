import {FC, useState, useTransition} from "react";
import {IconButton, Modal, Stack, Typography} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {useTranslation} from "react-i18next";
import {CustomButton} from "../../_styles/ButtonStyle";
import {ModalBox} from "../../_styles/ModalStyle";
import utilsService from "../../services/utils.service";
import DeleteIcon from "@mui/icons-material/Delete";

const DeleteModal: FC<{ id: string, entityName: string, targetable?: string,targetableId?: string}>
    = ({id, entityName, targetable, targetableId}) => {
    const {t} = useTranslation();
    const [loading, startTransition] = useTransition();
    const handleClick = () => {
        console.log('data', id,);
        startTransition(() => {
            utilsService.delete(id, entityName, t, targetable, targetableId)
        })
        handleClose()
    }

    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    }

    return (
        <>
            <IconButton color="error"
                        onClick={() => setOpen(true)}><DeleteIcon/></IconButton>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby={`delete-modal-${id}`}
                aria-describedby="delete-modal-description"
            >
                <ModalBox>
                    <Stack direction='column' justifyContent='center' spacing={2}>
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                            <Typography id="modal-modal-title" variant="h3" component="h2"
                                        style={{textAlign: "center"}}>
                                {t("delete", {ns: "glossary"}) + ' '
                                    + t("a_m", {ns: "glossary"}) + ' '
                                    + t(entityName, {ns: "glossary"}).toLowerCase()}
                            </Typography>
                            <IconButton onClick={handleClose} style={{fontSize: "normal"}}>
                                <CloseIcon fontSize="large"/>
                            </IconButton>
                        </Stack>
                        <Typography textAlign='center'>Il sera supprimer définitivement, êtes-vous sur de vouloir
                            continuer ?</Typography>
                        <Stack direction="row" spacing={1} justifyContent="space-between">
                            <CustomButton variant="contained" onClick={handleClose} color="error"
                            >  {t("cancel", {ns: 'glossary'})}</CustomButton>
                            <CustomButton disabled={loading} variant="contained" onClick={handleClick}>
                                {t("delete", {ns: 'glossary'})}</CustomButton>
                        </Stack>
                    </Stack>
                </ModalBox>
            </Modal>
        </>
    )
}

export default DeleteModal
