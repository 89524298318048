import {BehaviorSubject} from "rxjs";
import {Rating} from "../@types/rating";
import http from "./config/http.service";
import {Collection} from "../@types/collection";
import ratingEntity from "../_emptyEntity/rating";
import collection from "../_emptyEntity/Collection";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";

class ratingService {
    static baseUrl = process.env.REACT_APP_BASE_URL;
    static ratings: Collection = collection;
    static _ratings = new BehaviorSubject<Collection>(this.ratings);
    static rating: Rating = ratingEntity;
    static _rating = new BehaviorSubject<Rating>(this.rating);

    private static token: string = localStorage.getItem('API_TOKEN') ?? "";

    static getCollection = (t: any) => {
        http.get(this.baseUrl + 'ratings', {headers: {Authorization: this.token}})
            .subscribe({
                next: (e: any) => {
                    this.ratings = e.data["hydra:member"];
                    this._ratings.next(this.ratings);
                },
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"}))
            });
    };

    static post = (data: Partial<Rating>, t: any) => {
        http.post(this.baseUrl + `ratings`, data, {headers: {Authorization: this.token}})
            .subscribe({
                next: (e: any) => {
                    this.rating = {...ratingEntity, ...e.data};
                    this._rating.next(this.rating);
                },
                error: (err) => (err.response.status === 418) ?
                    toast.warning(t("errors.duplicate", {ns: "utils/notification"})) :
                    toast.error(t("errors.error_append", {ns: "utils/notification"})),
                complete: () => toast.success(t("rating", {ns: "glossary"}) + t("success.created", {ns: "utils/notification"}))
            });
    };

    static get = (id: string | undefined, t: any) => {
        http.get(this.baseUrl + `ratings/${id}`, {headers: {Authorization: this.token}})
            .subscribe({
                next: (e: any) => {
                    this.rating = {...ratingEntity, ...e.data};
                    this._rating.next(this.rating);
                },
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"}))
            });
    };

    static put = (id: string | undefined, data: Partial<Rating>, t: any) => {
        http.put(this.baseUrl + `ratings/${id}`, data, {headers: {Authorization: this.token}})
            .subscribe({
                next: (e: any) => {
                    this.rating = {...ratingEntity, ...e.data};
                    this._rating.next(this.rating);
                },
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"})),
                complete: () => toast.success(t("rating", {ns: "glossary"}) + t("success.modified", {ns: "utils/notification"}))
            });
    };

    static delete = (id: string | undefined, t:any) => {
        http.delete(this.baseUrl + `ratings/${id}`, {headers: {Authorization: this.token}})
            .subscribe({
                next: () => this.getCollection(t),
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"})),
                complete: () => toast.success(t("rating", {ns: "glossary"}) + t("success.deleted", {ns: "utils/notification"}))
            });
    };
}

export default ratingService;
