import React, {FC, useEffect, useState} from "react";
import {Box, CardActionArea, CardActions, CardMedia, IconButton, Link, Modal, Stack, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {Tutorial} from "../../../@types/tutorial";
import {ModalBox} from "../../../_styles/ModalStyle"
import {MyCard, MyCardContent, preview} from "../../../_styles/CardStyle";
import PEGI from "../../common/targetable/PEGI";
import Duration from "../../common/targetable/Duration";
import Cost from "../../common/targetable/Cost";
import MyRating from "../../common/targetable/MyRating";
import FavoriteIcon from "@mui/icons-material/Favorite";
import VisibilityIcon from '@mui/icons-material/Visibility';
import tagTarget from "../../../_emptyEntity/tagTarget";

const PreviewTutorial: FC<{ tutorial: Tutorial, openAction: boolean, setOpenAction: any }>
    = ({tutorial, openAction, setOpenAction}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [open, setOpen] = useState(openAction);
    const handleClose = () => {
        setOpen(false);
        setOpenAction(false)
    }

    useEffect(() => {
        setOpen(openAction)
    }, [openAction]);
//
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <ModalBox isNotVisible>
                <MyCard notransform difficulty={tutorial?.difficulty} sx={preview}>
                    <CardActionArea onClick={() => navigate(`/tutorials/${tutorial.id}`)}>
                        <Stack direction="row" justifyContent="space-between" alignItems="center"
                               sx={{position: 'absolute', width: 'inherit', color: "white", padding: '5px 15px 0'}}>
                            <Box sx={{
                                backgroundColor: "rgba(157, 88, 222, 0.5)",
                                padding: 0.4,
                                borderRadius: 0.6
                            }}>
                                <Typography fontWeight="bold">By {tutorial.pseudo}</Typography>
                            </Box>
                            <MyRating targetable={tutorial}/>
                        </Stack>
                        <CardMedia
                            component="img"
                            height="194"
                            image={tutorial?.coverPicture?.contentUrl}
                            alt={tutorial?.coverPicture?.name}
                        >
                        </CardMedia>
                        <MyCardContent>
                            <Stack spacing={1}>
                                <Stack direction="row" justifyContent={"space-between"} alignItems="center">
                                    <Typography variant="h3">{tutorial?.title}</Typography>
                                    <Stack sx={{paddingTop: 1}} direction="row">
                                        <IconButton><FavoriteIcon/></IconButton>
                                        <IconButton><FavoriteIcon/></IconButton>
                                        <IconButton><FavoriteIcon/></IconButton>
                                    </Stack>
                                </Stack>
                                <Stack minHeight={100}>
                                    <Typography  textAlign="justify" variant="body2">{tutorial?.description}</Typography>...
                                </Stack>
                                <Stack direction="row" justifyContent="space-evenly">
                                    <PEGI targetable={tutorial}/>
                                    <Duration targetable={tutorial}/>
                                    <Cost targetable={tutorial}/>
                                </Stack>
                                <Stack direction="row" spacing={1} justifyContent="space-around" alignItems="center"
                                       flexWrap="wrap">
                                    {tutorial?.tagTarget.tags?.map((tag, index) =>
                                        <Link key={index}>#{tag.label}</Link>
                                    )}
                                </Stack>
                            </Stack>
                        </MyCardContent>
                    </CardActionArea>
                    <CardActions sx={{justifyContent: "space-evenly"}}>
                        <Stack direction="row" spacing={2}>
                            <Typography variant="h4">{tutorial?.view}</Typography><VisibilityIcon/>
                        </Stack>
                        <Stack direction="row" spacing={2}>
                            <Typography variant="h4">{tutorial?.favoriteNumber}</Typography><FavoriteIcon/>
                        </Stack>
                    </CardActions>
                </MyCard>
            </ModalBox>
        </Modal>
    )
}

export default PreviewTutorial