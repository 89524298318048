import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import Page from "../../../components/layout/Page";
import {Grid} from "@mui/material";
import LikeItem from "../../../components/channel/ChannelItem";
import targetService from "../../../services/target.service";
import {LayoutContext} from "../../../layout/FullLayout";
import TutorialCard from "../../../components/common/card/TutorialCard";
import MakeCard from "../../../components/common/card/MakeCard";

const LikeCollection: React.FC<{
    pageType?: string;
}> = ({pageType}) => {
    const {t} = useTranslation();
    const [likes, setLikes] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [totalLike, setTotalLike] = useState(0);
    const [order, setOrder] = useState(`order[createdAt]=asc`);
    const {width} = useContext(LayoutContext);

    useEffect(() => {
        setLoading(true);
        targetService.getCollection("likes", t, "targetIri[]=make&targetIri[]=tutorial")
        const likes = targetService._targets.subscribe((e: any) => setLikes(e));
        setLoading(false);
        return () => {
            likes.unsubscribe();
        };
    }, []);
    // const api = `/likes?${`page=${params.page}`}&itemsPerPage=${12}
    // &${order}${params.term ? `&term=${params.term}` : ""}`;
    return (
        <Page title={t("like", {ns: "glossary"}) + 's'}>
              <Grid container>
                  {likes.length > 0 &&
                likes?.map((like: any, index:any) => (
                    <Grid item sm={6} md={4} lg={3} xl={2} key={index}>
                        {like.target['@type'] === 'Tutorial' &&
                            <TutorialCard tutorial={like.target}/>
                        }
                        {like.target['@type'] === 'Make' &&
                            <MakeCard make={like.target}/>
                        }
                    </Grid>
                ))}
            </Grid>
          {/*  {totalLike > likes?.length ?
                <Box display="center" justifyContent="center" sx={{mt: 2}}>
                    <Pagination
                        count={Math.ceil(totalLike / 10)}
                        page={httpParams.page}
                        onChange={(event, value) => handlePage(value)}
                        shape="rounded"
                    />
                </Box>
                :
                <Box sx={{mt: 6}}>
                    <Typography variant="body1" textAlign="center">
                        {t("LIST.NO_RESULT")}
                    </Typography>
                </Box>
            }*/}
        </Page>
    );
};

export default LikeCollection;