import {FC, useContext, useEffect, useState, useTransition} from 'react';
import {useTranslation} from "react-i18next";
import materialService from "../../../services/material.service";
import {Box, Button, Container, Grid, Paper, Stack} from "@mui/material";
import Page from "../../../components/layout/Page";
import {LayoutContext} from "../../../layout/FullLayout";
import TextInput from "../../../components/common/formInputs/TextInput";
import {CustomButton} from "../../../_styles/ButtonStyle";
import {Resolver, SubmitHandler, useForm} from "react-hook-form";
import {Material, MaterialJson} from "../../../@types/material";
import useFormHook from "../../../hooks/FormHook";
import FormAddOneMediaObject from "../../../components/mediaObject/FormAddOneMediaObject";
import {useParams} from "react-router-dom";
import PictureGallery from "../../../components/common/targetable/picture/PictureGallery";
import TargetablePictureManagementModal
    from "../../../components/common/targetable/picture/modal/TargetablePictureManagementModal";
import Loader from "../../../components/layout/Loader";

const MaterialItem: FC<{ pageType?: string; }> = ({pageType}) => {
    const {id} = useParams();
    const {t} = useTranslation();
    const [material, setMaterial] = useState<Material>();
    const [loading, startTransition] = useTransition();
    const {width} = useContext(LayoutContext);
    const [image, setImage] = useState()

    useEffect(() => {
        startTransition(() => materialService.get(id, t))
        const material = materialService._material.subscribe((e: any) => setMaterial(e));
        return () => {
            material.unsubscribe();
        };
    }, []);

    const resolver: Resolver<MaterialJson> = async (values) => {
        let errors = {};
        // Validate all fields on submit event
        errors = Object.entries(values).reduce(
            (acc, [name, value]) => (value !== "duration") ? useFormHook.hasError(name, value, acc, t) : null, {});
        console.log(errors)
        return {values, errors};
    };
    const {register, handleSubmit, watch, setError, formState: {errors}} = useForm<MaterialJson>({
        resolver,
        mode: "onBlur"
    });
    const onSubmit: SubmitHandler<MaterialJson> = data => {
        const postData = {...data, price: Number(data.price)}
        console.log('data', postData, errors);

        if (Object.keys(errors).length === 0) {
            startTransition(() => {
                materialService.put(material?.id, postData, t)
            })
        }
    };

    if (loading) {
        return (
            <Page sx={{ml: width}} title={t("cgu.title")}>
                <b className="pending"><Loader/></b>
            </Page>
        );
    }

    return (
        <Page sx={{ml: width}} title={t("cgu.title")}>
            <Container>
                <Paper sx={{height: '100%', p:2}}>
                    {!loading && material?.id &&
                        <Grid container>
                            <Grid item xs={12} md={6} pr={2}>
                            <PictureGallery coverPicture={material.coverPicture} pictures={material.pictures}/>
                            <TargetablePictureManagementModal target={material} mediaObject="material_media_objects" updateTargetId={material.id}/>
                        </Grid>
                            <Grid item xs={12} md={6} pr={5}>
                                <Box
                                    component="form"
                                    sx={{'& .MuiTextField-root': {m: 1, width: '25ch'}}}
                                    noValidate
                                    onSubmit={handleSubmit(onSubmit)}
                                >
                                    <Stack direction='column' sx={{width: '100%'}}>
                                        <TextInput name='name' register={register} required error={errors.name}
                                                   defaultValue={material.name}
                                                   helperText={(errors?.name?.message) ? t(errors.name.message, {ns: 'common/form'}) : ''}
                                                   label={t('label.common.name', {ns: 'common/form'})}/>
                                        <TextInput name='description' register={register} required multiline
                                                   error={errors.description} defaultValue={material.description} rows={5} maxRows={10}
                                                   helperText={(errors?.description?.message) ? t(errors.description.message, {ns: 'common/form'}) : ''}
                                                   label={t('label.common.description', {ns: 'common/form'})}/>
                                        <TextInput name='price' register={register} required
                                                   inputProps={{subStep: 0.01, min: "0"}} type='number'
                                                   error={errors.price}
                                                   defaultValue={material.price}
                                                   helperText={(errors?.price?.message) ? t(errors.price.message, {ns: 'common/form'}) : ''}
                                                   label={t('label.material.price', {ns: 'common/form'})}/>
                                        <TextInput name='link' register={register} required error={errors.link}
                                                   defaultValue={material.link}
                                                   helperText={(errors?.link?.message) ? t(errors.link.message, {ns: 'common/form'}) : ''}
                                                   label={t('label.common.link', {ns: 'common/form'})}/>
                                    </Stack>
                                    <Box mt={3}>
                                        <CustomButton disabled={loading} variant="contained" type="submit">
                                            {t("edit", {ns: 'glossary'})}
                                        </CustomButton>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    }
                </Paper>
            </Container>
        </Page>
    );
};

export default MaterialItem;
