import {BoxProps, styled, TableCell, tableCellClasses, TableRow, TableRowProps} from "@mui/material";
import {alpha} from "@mui/material/styles";

export const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        textAlign:'center',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        textAlign: 'center',
        fontSize: 14,
    },
}));

interface StyledTableRowProps extends TableRowProps<any> {
    difficulty?: string
}

export const StyledTableRow = styled(TableRow)<StyledTableRowProps>(({theme, status}) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
    '&:nth-of-type(odd), &:nth-of-type(even)': {
    ...(status === "draft") && {
        backgroundColor: `${alpha(theme.palette.warning.main, 0.4)}`
    },
    ...(status === "published") && {
        backgroundColor: `${alpha(theme.palette.success.main, 0.4)}`
    },
    ...(status === "disabled") && {
        backgroundColor: `${alpha(theme.palette.error.main, 0.4)}`
    }
    }

}));