import React, {useEffect, useState, useTransition} from 'react';
import tutorialService from "../../../services/tutorial.service";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/material/styles";
import {Tutorial} from "../../../@types/tutorial";
import Page from "../../../components/layout/Page";
import {Container, Grid} from "@mui/material";
import FileSection from "../../../components/workroom/tutorial/section/FileSection";
import StepSection from "../../../components/workroom/tutorial/section/StepSection";
import TagSection from "../../../components/workroom/tutorial/section/TagSection";
import TutorialSection from "../../../components/workroom/tutorial/section/TutorialSection";
import MaterialSection from "../../../components/workroom/tutorial/section/MaterialSection";

const files = [
    {name: "file_1"},
    {name: "file_2"},
    {name: "file_3"},
    {name: "file_4"},
    {name: "file_5"},
]

const WorkroomTutorialEdition = () => {
    const {id} = useParams();
    const {t} = useTranslation();
    const theme = useTheme();
    const [loading, startTransition] = useTransition();
    const [tutorial, setTutorial] = useState<Tutorial>();

    useEffect(() => {
        startTransition(() => tutorialService.get(id, t))
        const tutorial = tutorialService._tutorial.subscribe((e: any) => setTutorial(e));
        return () => {
            tutorial.unsubscribe();
        };
    }, [id, t]);

    return (
        <>
            {(!loading && id && tutorial?.id) &&
            <Page title={t("tutorial", {ns: "glossary"}) + 's - ' + tutorial?.title}>
                <Container>
                    <Grid container spacing={2}>
                        <TutorialSection tutorial={tutorial}/>
                        <MaterialSection tutorial={tutorial}/>
                        <TagSection tutorial={tutorial}/>
                        <FileSection tutorial={tutorial} files={files}/>
                        <StepSection tutorial={tutorial}/>
                    </Grid>
                </Container>
            </Page>
            }
        </>
    )
};

export default WorkroomTutorialEdition;
