import React, {FC, useContext, useEffect, useState, useTransition} from "react";
import {Box, Button, Container, Divider, Grid, Paper, Stack, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import Page from "../../../components/layout/Page";
import {LayoutContext} from "../../../layout/FullLayout";
import {CustomButton} from "../../../_styles/ButtonStyle";
import {useNavigate} from "react-router-dom";
import {Resolver, SubmitHandler, useForm} from "react-hook-form";
import useFormHook from "../../../hooks/FormHook";
import userService from "../../../services/user.service";
import {User} from "../../../@types/user";
import SaveIcon from '@mui/icons-material/Save';
import Email from "../../../components/user/Email";
import Password from "../../../components/user/Password";
import {PersonContext} from "../../../App";
import personService from "../../../services/person.service";

const EditAccount: FC = () => {
    const {width} = useContext(LayoutContext);
    const {currentPerson} = useContext(PersonContext);
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [loading, startTransition] = useTransition();
    const [user, setUser] = useState<User>();

    useEffect(() => {
        startTransition(() => {
            personService.get(currentPerson.id, t)
        })
        const user = userService._user.subscribe((e: any) => setUser(e.user))
        return () => {
            user.unsubscribe();
        };
    }, []);

    const resolver: Resolver<User> = async (values) => {
        let errors = {};
        // Validate all fields on submit event
        errors = Object.entries(values).reduce(
            (acc, [name, value]) => (value !== "duration") ? useFormHook.hasError(name, value, acc, t) : null, {});
        console.log(errors)
        return {values, errors};
    };
    const {register, handleSubmit, watch, setError, formState: {errors}} = useForm<User>({
        resolver,
        mode: "onBlur"
    });
    const onSubmit: SubmitHandler<User> = data => {
        console.log('data', data, errors);
        if (Object.keys(errors).length === 0) {
            startTransition(() => {
                userService.put(user?.id,data, t)
            })
        }
    };

    return (
        <Page sx={{ml: width}} title={t("account", {ns: "glossary"})}>
            <Container>
                <Grid item xs={12}>
                    <Paper sx={{}}>
                        <Stack direction="row"  spacing={1} justifyContent="space-between" divider={<Divider variant="middle" orientation="vertical"/>}>
                            <Box sx={{padding: '10px', width:'50%'}}>
                                <Stack direction="row"  justifyContent="space-between" alignItems="center">
                                    <Typography variant="h4">
                                        {t("account", {ns: "glossary"})}
                                    </Typography>
                                    <Button variant="contained" disabled={loading} type="submit">
                                        <SaveIcon/>
                                    </Button>
                                </Stack>
                                <Divider variant='fullWidth'/>
                                <Stack
                                    spacing={2}
                                    component="form"
                                    sx={{'& .MuiTextField-root': {m: 1, width: '25ch'}}}
                                    noValidate
                                    onSubmit={handleSubmit(onSubmit)}
                                >
                                    <Email register={register} email={watch("email", '')} errors={errors}
                                           value={user?.email}/>
                                    <Password register={register} value={watch('password', '')} errors={errors}/>
                                    <CustomButton disabled={loading} variant="contained" color="error" type="submit">
                                        {t("delete", {ns: "glossary"})}
                                    </CustomButton>
                                    <Stack width="50%" justifyContent="center" alignItems="center">
                                        <Typography variant="body1">Pouvoir supprimer mon historique</Typography>
                                        <Typography variant="body1">Pouvoir télécharger mes logs</Typography>
                                        <Typography variant="body1">Gérer mon abonnement</Typography>
                                        <Typography variant="body1"></Typography>
                                        <Typography variant="body1"></Typography>
                                        <Typography variant="body1"></Typography>
                                    </Stack>
                                </Stack>
                            </Box>
                    <img src={"/assets/images/account_page_img.png"} style={{width: '50%'}} alt="signin_background"/>
                        </Stack>
                    </Paper>
                </Grid>
            </Container>
        </Page>
    );
};

export default EditAccount
