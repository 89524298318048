// ----------------------------------------------------------------------

export default function Box(theme: any) {
  return {
    MuiBox: {
      styleOverrides: {
        root: {
          display:"grid",
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
  };
}
