const tagEntity = {
    "@context": "",
    "@id": "",
    "@type": "",
    "id": "",
    "label": "",
    "color": "",
    "type": "",
    "targetIri": "",
    "target": {},
    "createdAt": "",
    "updatedAt": ""
};
export default tagEntity;