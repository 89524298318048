import Person from "./person";

const commentEntity = {
    "@context": "",
    "@id": "",
    "@type": "",
    "id": "",
    "content": "",
    "targetIri": "",
    "files": [],
    "ratings": [],
    "likeNumber": 0,
    "isLiked": false,
    "person": Person,
    "childrens": [],
    "createdAt": "",
    "updatedAt": "",
    "createdBy": {},
    "updatedBy": {}
};
export default commentEntity;