// ----------------------------------------------------------------------

import {alpha} from "@mui/material/styles";

export default function Popover(theme: any) {
  return {
    MuiPopover: {
      styleOverrides: {
        paper: {
          padding:'10px',
          boxShadow:'none',
          border: `4px solid ${alpha(theme.palette.primary.main, 0.8)}`
        },
      },
    },
  };
}
