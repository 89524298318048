import {BehaviorSubject} from "rxjs";
import {TagTarget} from "../@types/tagTarget";
import http from "./config/http.service";
import {Collection} from "../@types/collection";
import tagTargetEntity from "../_emptyEntity/tagTarget";
import collection from "../_emptyEntity/Collection";
import {toast} from "react-toastify";
import TutorialService from "./tutorial.service";
import MaterialService from "./material.service";
import MakeService from "./make.service";
import ChannelService from "./channel.service";
import makeEntity from "../_emptyEntity/make";

class tagTargetService {
    static baseUrl = process.env.REACT_APP_BASE_URL;
    static tagTargets: Collection = collection;
    static _tagTargets = new BehaviorSubject<Collection>(this.tagTargets);
    static tagTarget: TagTarget = tagTargetEntity;
    static _tagTarget = new BehaviorSubject<TagTarget>(this.tagTarget);
    private static token: string = localStorage.getItem('API_TOKEN') ?? "";

    static setTagTarget = (target: TagTarget) => {
        this.tagTarget = target;
        this._tagTarget.next(this.tagTarget);
    }

    static put = (id: string | undefined, data: Partial<TagTarget>, t: any) => {
        console.log('TagTarget put service', data)
        http.put(this.baseUrl + `tag_targets/${id}`, data, {headers: {Authorization: this.token}})
            .subscribe({
                next: (e: any) => {
                    console.log('e.data put service', e.data)
                    this.tagTarget = {...tagTargetEntity, ...e.data};
                    this._tagTarget.next(this.tagTarget);
                },
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"})),
                complete: () => toast.success(t("tagTarget", {ns: "glossary"}) + t("success.modified", {ns: "utils/notification"}))
            });
    };

    static delete = (index:number, t: any) => {
        console.log('index service', index)
        console.log('tags service', this.tagTarget.tags.map((tag) => tag['@id']))
        this.tagTarget.tags.splice(index,1)
        console.log('Tags delete service', this.tagTarget.tags)
        let data: Partial<TagTarget> = {tags: this.tagTarget.tags.map((tag,index) => tag['@id'])}
       // console.log('tagtarget delete',this.tagTarget)
        console.log('TagTarget service', data)
        this.put(this.tagTarget.id, data, t)
    };
}

export default tagTargetService;