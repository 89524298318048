import React, {FC} from 'react';
import Filter1Icon from '@mui/icons-material/Filter1';
import Filter2Icon from '@mui/icons-material/Filter2';
import Filter3Icon from '@mui/icons-material/Filter3';
import Filter6Icon from '@mui/icons-material/Filter6';
import Filter7Icon from '@mui/icons-material/Filter7';
import Filter8Icon from '@mui/icons-material/Filter8';

const PEGI: FC<{ targetable: any | undefined }> = ({targetable}) => {

    return (
        <>
        {targetable?.PEGI === 'threeYearsOld' &&
        <Filter3Icon/>
        }
        {targetable?.PEGI === 'sevenYearsOld' &&
            <Filter7Icon/>
        }
        {targetable?.PEGI === 'twelveYearsOld' &&
            <><Filter1Icon/><Filter2Icon/></>
        }
        {targetable?.PEGI === 'sixteenYearsOld' &&
            <><Filter1Icon/><Filter6Icon/></>
        }
        {targetable?.PEGI === 'eighteenYearsOld' &&
            <><Filter1Icon/><Filter8Icon/></>
        }
        </>
    );
};

export default PEGI
