import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import resourcesToBackend from 'i18next-resources-to-backend'


i18n
    .use(LanguageDetector) // passes i18n down to react-i18next
    .use(resourcesToBackend((language: any, namespace: any) => import(`./locales/${language}/${namespace}.json`)))
    .use(initReactI18next)
    .init({
        //debug: true,
        fallbackLng: "fr",
        ns: [
            'translation',
            'glossary',
            'header/appBar',
            'header/sideBar',
            'page/admin',
            'page/dashboard',
            'page/forgotPassword',
            'common/form',
            'page/login',
            'reSearchComposant',
            'page/signUp',
            'page/userAccount',
            'common/feedback',
            'common/rating',
            'common/report',
            'tutorial/tutorial',
            'material',
            'tutorial/subStep',
            'tutorial/step',
            'utils/notification',
            'footer/footer'
        ],
        saveMissing: true,
        load: 'languageOnly',
        interpolation: {
            escapeValue: false // react already safes from xss
        },
    })
;

export default i18n;

