import React, {FC} from 'react';
import {Stack, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import TimelapseIcon from '@mui/icons-material/Timelapse';
import {format, formatDuration, parseISO} from "date-fns";
import {fr} from "date-fns/locale";

const Duration: FC<{ targetable: any | undefined }> = ({targetable}) => {
    const {t} = useTranslation()
    const hours: number = Number(format(new Date(parseISO(targetable?.duration?? '1970-01-01T00:00:00+00:00')), 'h'));
    const minutes: number = Number(format(new Date(parseISO(targetable?.duration?? '1970-01-01T00:00:00+00:00')), 'm'));

    return (
        <Stack direction="row" spacing={2} alignItems="center">
            <TimelapseIcon fontSize="large"/>
            <Typography variant="body1">{(!!targetable?.duration) ?
                formatDuration({
                    hours: hours,
                    minutes: minutes,
                }, {locale: fr}) : ""}</Typography>
        </Stack>
    );
};

export default Duration
