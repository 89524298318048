import tutorial from "./tutorial";
import person from "./person";
import mediaObject from "./MediaObject";

const makeEntity = {
    "@context": "",
    "@id": "",
    "@type": "",
    "id": "",
    "ratings": [],
    "ratingNumber": 0,
    "averageRating": 0,
    "likeNumber": 0,
    "isLiked": false,
    "favoriteNumber": 0,
    "isFavorite": false,
    "tutorial": tutorial,
    coverPicture: mediaObject,
    pictures: [],
    "person": person,
    "pseudo": "",
    "createdAt": "",
    "updatedAt": ""
};
export default makeEntity;