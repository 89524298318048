import React, {useContext, useEffect, useState, useTransition} from "react";
import {Box, Button, Container, Divider, Grid, Paper, Skeleton, Stack, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import Page from "../../../components/layout/Page";
import {LayoutContext} from "../../../layout/FullLayout";
import {useNavigate} from "react-router-dom";
import {Resolver, SubmitHandler, useForm} from "react-hook-form";
import useFormHook from "../../../hooks/FormHook";
import SaveIcon from "@mui/icons-material/Save";
import TextInput from "../../../components/common/formInputs/TextInput";
import {CustomButton} from "../../../_styles/ButtonStyle";
import {Person} from "../../../@types/person";
import PersonService from "../../../services/person.service";
import personService from "../../../services/person.service";
import {PersonContext} from "../../../App";

const EditProfile = () => {
    const {width} = useContext(LayoutContext);
    const {currentPerson} = useContext(PersonContext);
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [loading, startTransition] = useTransition();
    const [person, setPerson] = useState<Person>();
    const url = window.location.href

    useEffect(() => {
        startTransition(() => {
            personService.get(currentPerson.id, t)
        })
        const user = personService._person.subscribe((e: any) => setPerson(e))
        return () => {
            user.unsubscribe();
        };
    }, []);

    const resolver: Resolver<Person> = async (values) => {
        let errors = {};
        // Validate all fields on submit event
        errors = Object.entries(values).reduce(
            (acc, [name, value]) => useFormHook.hasError(name, value, acc, t), {});
        console.log(errors)
        return {values, errors};
    };
    const {register, handleSubmit, watch, setError, formState: {errors}} = useForm<Person>({
        resolver,
        mode: "onBlur"
    });
    const onSubmit: SubmitHandler<Person> = data => {
        console.log('data', data, errors);
        if (Object.keys(errors).length === 0) {
            startTransition(() => {
                PersonService.put(currentPerson.id, data, t)
            })
        }
    }

    return (

        <Page sx={{ml: width}} title={t("account", {ns: "glossary"})}>
            <Container>
                <Grid item xs={12}>
                    <Paper sx={{}}>
                        <Box
                            component="form"
                            sx={{'& .MuiTextField-root': {m: 1, width: '25ch'}}}
                            noValidate
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <Stack direction="row" sx={{padding: '10px'}} justifyContent="space-between"
                                   alignItems="center">
                                <Typography variant="h4">
                                    {t("profil", {ns: "glossary"})}s
                                </Typography>
                                <Button variant="contained" disabled={loading} type="submit">
                                    <SaveIcon/>
                                </Button>
                            </Stack>
                            <Divider variant='fullWidth'/>
                            {(!loading && person) &&
                                <Stack direction="row">
                                    <Skeleton variant="rectangular" width={400} height={200}/>
                                <Stack justifyContent="center" sx={{p: '20px', width: '50%'}}>
                                    <TextInput name="firstName" register={register} error={errors.firstName}
                                               defaultValue={person.firstName} value={watch('firstName', '')}
                                               helperText={errors.firstName?.message}
                                               label={t("label.person.firstName", {ns: "common/form"})}/>
                                    <TextInput name='lastName' register={register} error={errors.lastName}
                                               defaultValue={person.lastName} value={watch('lastName', '')}
                                               helperText={errors.lastName?.message}
                                               label={t("label.person.lastName", {ns: "common/form"})}/>
                                    <TextInput name='pseudo' register={register} error={errors.pseudo}
                                               defaultValue={person.pseudo} value={watch('pseudo', '')}
                                               helperText={errors.pseudo?.message}
                                               label={t("label.person.pseudo", {ns: "common/form"})}/>
                                </Stack>
                                </Stack>
                            }
                        </Box>
                        <CustomButton disabled={loading} variant="contained" color="error" type="submit">
                            {t("delete", {ns: "glossary"})}
                        </CustomButton>
            <img width="inherit" src={"/assets/images/profile_page_img.png"} style={{width: '-webkit-fill-available'}} alt="signin_background"/>
                    </Paper>
                </Grid>
            </Container>
        </Page>

    );
};

export default EditProfile
