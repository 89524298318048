import mediaObjectThumbnailEntity from "./MediaObjectThumbnail";

const mediaObjectEntity = {
    "@context": "",
    "@id": "",
    "@type": "",
    id: "",
    file:"",
    name: "",
    type:"",
    targetIri: "",
    target: {},
    thumbnail: mediaObjectThumbnailEntity,
    contentUrl:"",
    createdAt: "",
    updatedAt: ""
};
export default mediaObjectEntity;