import {BehaviorSubject} from "rxjs";
import http from "./config/http.service";
import {Target} from "../@types/target";
import tutorialService from "./tutorial.service";
import makeService from "./make.service";
import targetEntity from "../_emptyEntity/target";
import {toast} from "react-toastify";
import {Collection} from "../@types/collection";
import collection from "../_emptyEntity/Collection";
import commentService from "./comment.service";

class targetService {
    static baseUrl = process.env.REACT_APP_BASE_URL;
    static targets: Collection = collection;
    static _targets = new BehaviorSubject<Collection>(this.targets);
    static target: Target = targetEntity;
    static _target = new BehaviorSubject<Target>(this.target);

    private static token: string = localStorage.getItem('API_TOKEN') ?? "";

    static getCollection = (targetable: string,t: any, filter?: string) => {
        http.get(this.baseUrl + targetable + "?" + filter, {headers: {Authorization: this.token}})
            .subscribe({
                next: (e: any) => {
                    this.targets = e.data["hydra:member"];
                    this._targets.next(this.targets);
                },
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"}))
            });
    };

    static post = (data: Partial<Target>, targetable: string, target: string, variableName: string[], t: any) => {
        http.post(this.baseUrl + targetable, data, {headers: {Authorization: this.token}})
            .subscribe({
                next: (e: any) => {
                    switch (target) {
                        case 'tutorials':
                            tutorialService.incrementTarget(e.data.id, variableName);
                            break;
                        case 'makes':
                            makeService.incrementTarget(e.data.id, variableName);
                            break;
                        case 'comments':
                            commentService.incrementTarget(e.data.id, variableName);
                    }
                },
                error: (err) => (err.response.status === 418) ?
                    toast.warning(t("errors.duplicate", {ns: "utils/notification"})) :
                    toast.error(t("errors.error_append", {ns: "utils/notification"})),
                complete: () => toast.success(t("success.yeah", {ns: "utils/notification"}))
            });
    };
    static deleteInItem = (id: string | undefined, targetable: string, target: string, variableName: string[], t: any) => {
        http.delete(this.baseUrl + `${targetable}/${id}`, {headers: {Authorization: this.token}})
            .subscribe({
                next: (e: any) => {
                    switch (target) {
                        case 'tutorials':
                            tutorialService.decrementTarget(variableName);
                            break;
                        case 'makes':
                            makeService.decrementTarget(variableName);
                    }
                },
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"}))
            });
    };

    static delete = (id: string | undefined, targetable: string, t: any) => {
        http.delete(this.baseUrl + `${targetable}/${id}`, {headers: {Authorization: this.token}})
            .subscribe({
                error: (err) => toast.error(t("errors.error_append", {ns: "utils/notification"})),
                complete: () => toast.success(t("success.delete", {ns: "utils/notification"}))
            });
    };

}

export default targetService;
